<template>
  <div class="order-creator-service-machine">
    <div
      v-if="dataLoaded">
      <div
        v-for="(machine, index) of machines"
        :key="'machine-item-' + index"
        class="mt-4 px-0 mx-0 pb-4 border-bottom">
        <div class="d-flex align-items-start product-info flex-wrap">
          <d-row class="mt-2 w-100">
            <d-col md="5">
              <div class="form-group">
                <label :for="'service-machine-' + index">
                  Maszyna:
                </label>
                <validation-provider
                  :name="'service-machine-' + index"
                  rules="required"
                  v-slot="{ errors }">
                  <div
                    :class="{
                      'form-control': true,
                      'is-select': true,
                      'is-invalid': errors.length,
                      'vs-wrap-text': true
                    }">
                    <v-select
                      :disabled="machine.id !== 0 && isMachineDisabled(machine, index)"
                      :options="getSupportedMachines()"
                      v-model="machines[index].machine_id"
                      :reduce="option => option.id"
                      placeholder="Wybierz maszynę"
                      label="name">
                      <template slot="no-options">
                        Brak maszyn realizujących wybrane zdobienie
                      </template>
                    </v-select>
                  </div>
                </validation-provider>
              </div>
            </d-col>
            <d-col md="2">
              <div class="form-group">
                <label :for="'service-machine-quantity-' + index">
                  Liczba do wykonania:
                </label>

                <validation-provider
                  :rules="'only-natural-numbers|max_value:' + getMaxExecutionQuantity(index) + '|min_value:' + getMinExecutionQuantity(index)"
                  :name="'service-machine-quantity-' + index"
                  v-slot="{ errors }">
                  <d-input-group
                    append="szt."
                    :class="{'is-invalid': errors.length}">
                    <d-input
                      :min="getMinExecutionQuantity(index)"
                      step="1"
                      :max="getMaxExecutionQuantity(index)"
                      :disabled="machine.id !== 0 && isMachineDisabled(machine, index)"
                      v-model="machine.quantity"
                      class="text-right"
                      @change="isReorder === true ? executionCountChanged(index, $event) : ''"
                      :state="errors.length ? 'invalid' : null"
                      type="number" />
                  </d-input-group>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
            <d-col md="2">
              <div class="form-group">
                <div class="mb-3 text-right">
                  Wykonano:
                </div>
                <div class="text-right">
                  {{ getDoneCount(machine.executions) }} szt.
                </div>
              </div>
            </d-col>
            <d-col
              md="3"
              class="d-flex">
              <d-button
                v-if="machine.id"
                :disabled="machine.id !== 0 && isMachineExecutionDisabled(machine, index)"
                theme="success"
                outline
                @click.prevent="
                          $bus.$emit(
                            'machine-execution-popup-show',
                            machine.id,
                            machine.quantity,
                            getDoneCount(machine.executions),
                            allWorkers,
                            serviceName,
                            orderName,
                            productCode
                          )"
                class="ml-auto align-self-center"
                title="Wprowadź Wykonanie">
                Wprowadź wykonanie
              </d-button>
              <d-button
                v-if="!machine.id"
                theme="danger"
                outline
                class="ml-auto align-self-center"
                title="Usuń"
                @click.prevent="removeMachine(index)">
                <i class="material-icons">delete</i>
                Usuń
              </d-button>
            </d-col>
          </d-row>
        </div>
        <div
          v-if="machine.machine_comments && machine.machine_comments.length"
          class="d-flex flex-column">
            <d-row class="mt-0 ml-0">
              <d-link
                class="dropdown-toggle"
                v-d-toggle="'machine-' + index + '-comments-collapse'">
                Uwagi
              </d-link>
            </d-row>
            <d-collapse
              :id="'machine-' + index + '-comments-collapse'">
              <div>
                <order-machine-comments :machine-comments="machine.machine_comments" />
              </div>
            </d-collapse>
          </div>
      </div>
    </div>
    <div
      v-if="!dataLoaded"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import OrderMachineComments from '@/components/forms/order/OrderCreatorServiceMachineComments.vue';

export default {
  name: 'order-creator-service-machine',
  components: {
    'order-machine-comments': OrderMachineComments
  },
  props: [
    'machines',
    'allMachines',
    'allWorkers',
    'serviceName',
    'serviceID',
    'orderServiceID',
    'orderName',
    'productCode',
    'serviceQuantity',
    'isReadOnly',
    'isReorder',
    'differenceAccpeted',
    'rejectedCount'
  ],
  data () {
    return {
      dataLoaded: false,
      realAdditionalCount: null
    };
  },
  mounted () {
    for (let i = 0; i < this.machines.length; i++) {
      this.machines[i].initialQuantity = this.machines[i].quantity;
    }
    this.dataLoaded = true;

    if (this.differenceAccpeted && this.rejectedCount > 0) {
      this.realAdditionalCount = this.rejectedCount - this.getNotAssignedCount();
    }
  },
  methods: {
    addMachine (defaultQuantity = 0) {
      this.machines.push({
        id: 0,
        machine_id: null,
        quantity: defaultQuantity,
        executions: [],
        initialQuantity: defaultQuantity
      });

      Vue.nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      });
    },
    getMachines() {
      let machinesToSend = [];
      for (let i = 0; i < this.machines.length; i++) {
        if (this.machines[i].machine_id) {
          machinesToSend.push({
            machine_id: this.machines[i].machine_id,
            quantity: this.machines[i].quantity,
            initialQuantity: this.machines[i].quantity
          });
          if (this.machines[i].id) {
            machinesToSend[i].id = this.machines[i].id
          }
        }
      }

      return machinesToSend;
    },
    getDoneCount (executions) {
      let counter = 0;
      for (let i = 0; i < executions.length; i++) {
        counter += executions[i].execution;
      }

      return counter;
    },
    getNotAssignedCount () {
      let rejected = this.rejectedCount;
      if (this.realAdditionalCount) {
        rejected = this.realAdditionalCount;
      }

      return (this.serviceQuantity + rejected) - this.machines.reduce((sum, item) => sum + Number(item.quantity), 0);
    },
    executionCountChanged (index, newValue) {
      this.$bus.$emit('service-reorder-not-full', Number(newValue) - Number(this.machines[index].initialQuantity), this.orderServiceID);
    },
    removeMachine (machineIndex) {
      this.machines.splice(machineIndex, 1);
    },
    getMaxExecutionQuantity (index) {
      if (this.isReadOnly) {
        return this.machines[index].quantity;
      }

      let rejected = this.rejectedCount;
      if (this.realAdditionalCount) {
        rejected = this.realAdditionalCount;
      }

      let fieldsSum = 0
      for (let i = 0; i < this.machines.length; i++) {
        if (i !== index) {
          fieldsSum += Number(this.machines[i].quantity);
        }
      }

      return (Number(this.serviceQuantity) + Number(rejected)) - fieldsSum;
    },
    getMinExecutionQuantity (index) {
      if (this.isReadOnly) {
        return this.machines[index].quantity;
      }

      return this.getDoneCount(this.machines[index].executions);
    },
    getSupportedMachines () {
      return this.allMachines.filter(machine => machine.supported_services.indexOf(this.serviceID) >= 0);
    },
    isMachineDisabled (machine, index) {
      if (this.isReadOnly) {
        return true;
      }

      return this.getDoneCount(machine.executions) === this.getMaxExecutionQuantity(index);
    },
    isMachineExecutionDisabled (machine) {
      if (this.isReadOnly) {
        return true;
      }

      return this.getDoneCount(machine.executions) === (machine.initialQuantity);
    }
  }
};
</script>

<style lang="scss">
  button:disabled {
    cursor: not-allowed!important;
  }
</style>
