<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">Zamówienia do zrealizowania</h3>
      </d-col>
      <d-col sm="3" class="align-self-end">
        <time-tracker />
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="orders-operator-filters-form"
        tag="form"
        class="d-flex mb-3">

        <d-input
          placeholder="Podaj nazwę zamówienia"
          v-model="filters.order_name"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card
      v-if="!loaded || loadError || !machines || !machines.length">
      <d-card-body class="text-center">
        <div v-if="!loaded">
          Trwa ładowanie danych&hellip;
        </div>
        <d-alert v-else-if="loadError" show theme="warning">
          Wczytywanie danych nie powiodło się.
          <a
            href="javascript:window.location.reload();"
            class="alert-link">
            Odśwież stronę
          </a>
          aby spróbować ponownie.
        </d-alert>
        <div v-else-if="!machines || !machines.length">
          Brak przydzielonych zamówień do wykonania.
        </div>
      </d-card-body>
    </d-card>

    <d-card
      v-for="(machine, index) of machines" :key="'machine-' + index"
      style="width: 100%"
      class="mb-5">
      <d-card-header class="text-center h5 mb-0">
        {{ machine.name }}
      </d-card-header>
      <d-card-body class="px-0 pb-2 pt-0">
        <table class="table mt-0 mb-0 full-width">
          <thead class="bg-light">
            <tr>
              <th scope="col" class="border-0 align-middle pl-4">Zamówienie</th>
              <th scope="col" class="border-0 align-middle pl-4">Produkt</th>
              <th scope="col" class="border-0 align-middle pl-4">Zdobienie</th>
              <th scope="col" class="border-0 align-middle pl-4">Nr DST</th>
              <th scope="col" class="border-0 align-middle pl-4">Kolory nici</th>
              <th scope="col" class="border-0 align-middle">Do wykonania</th>
              <th scope="col" class="border-0 align-middle text-center pr-4" style="width: 210px;">Akcje</th>
            </tr>
          </thead>
          <tbody v-if="loaded">
            <tr
              v-for="(item, indexj) of machine.items"
              :key="'machine-' + index + 'item-' + indexj">
              <td class="align-middle py-lg-3 py-md-3 py-3 pl-4">
                <label class="table-mobile-label mb-1">Zamówienie</label>
                {{ item.order_name }}
              </td>
              <td class="align-middle py-lg-3 py-md-3 py-3 pl-4">
                <label class="table-mobile-label mb-1">Produkt</label>
                Kod: {{ item.product_code }}<br>
                <small>Kolor: {{ item.product_color_name }}</small>
              </td>
              <td class="align-middle py-lg-3 py-md-3 py-3 pl-4">
                <label class="table-mobile-label mb-1">Zdobienie</label>
                {{ item.service_name }}
              </td>
              <td class="align-middle py-lg-3 py-md-3 py-3 pl-4">
                <label class="table-mobile-label mb-1">Nr DST</label>
                {{ item.service_dst_number }}
              </td>
              <td class="align-middle py-lg-3 py-md-3 py-3 pl-4">
                <label class="table-mobile-label mb-1">Kolory nici</label>
                {{ item.service_strands.join('; ')}}
              </td>
              <td class="align-middle py-lg-3 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Do wykonania</label>
                {{ item.quantity - item.execution }} szt.
              </td>
              <td class="text-center align-middle pt-0">
                <d-button
                  theme="success"
                  class="mx-2 mt-2"
                  title="Wykonaj"
                  @click.prevent="addExecution(item)">
                  <i class="material-icons">add</i>
                </d-button>
                <d-button
                  theme="info"
                  class="mx-2 mt-2"
                  title="Uwagi do maszyny"
                  @click.prevent="addCommentToMachine(item)">
                  <i class="material-icons">feedback</i>
                </d-button>
                <d-button
                  theme="primary"
                  @click.native="$router.push('/zamowienia/maszyny/edycja/' + item.id)"
                  class="mx-2 mt-2"
                  title="Zobacz">
                  <i class="material-icons">visibility</i>
                </d-button>
              </td>
            </tr>
            <tr v-if="machine.items.length === 0">
              <td colspan="7" class="text-center pr-4">
                Brak zdobień do wykonania&hellip;
              </td>
            </tr>
          </tbody>
        </table>
      </d-card-body>
    </d-card>

    <order-creator-service-machine-execution
      :orderID="0"
      :operatorDescription="null"
      :sourceView="'operatorListView'"  />
    <order-creator-service-machine-comment
      :sourceView="'operatorListView'" />
  </d-container>
</template>

<script>
import Vue from 'vue';
import ListUtils from './../utils/ListUtils.js';
import FormUtils from './../utils/FormUtils.js';
import OrderCreatorServiceMachineExecutionPopup from './../components/popups/OrderCreatorServiceMachineExecutionPopup.vue';
import OrderCreatorServiceMachineCommentPopup from './../components/popups/OrderCreatorServiceMachineCommentPopup.vue';
import debounce from 'lodash.debounce';
import TimeTracker from '@/components/common/TimeTracker.vue';

export default {
  name: 'orders-operator',
  components: {
    'order-creator-service-machine-execution': OrderCreatorServiceMachineExecutionPopup,
    'order-creator-service-machine-comment': OrderCreatorServiceMachineCommentPopup,
    'time-tracker': TimeTracker
  },
  data() {
    return {
      machines: [],
      loaded: false,
      loadError: false,
      operatorItem: null,
      loadedOperatorItem: false,
      filters: {
        order_name: ''
      }
    };
  },
  mounted() {
    this.loadFilteredData();
    this.$bus.$on('reload-operator-list', this.loadFilteredData);
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.order_name) {
        where.order_name = this.filters.order_name;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/orders/machines/operator-items',
        listField: 'machines',
        pagination: false,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.filters = {
        order_name: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['orders-operator-filters-form'], this.debouncedLoadFilteredData);
    },
    addExecution(item) {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/orders/machines/operator-item/' + item.id,
        method: 'get',
        outputKey: 'operatorItem',
        loadedKey: 'loadedOperatorItem',
        errorKey: 'loadError',
        successAction: () => {
          Vue.nextTick(() => {
            this.$bus.$emit('machine-execution-popup-show', this.operatorItem.id, this.operatorItem.quantity, this.operatorItem.quantity - this.getToExecuteAmount(), [], this.operatorItem.service.service_name, '', this.operatorItem.service.product.product_code, item.order_id, item.order_operator_description);
          });
        }
      });
    },
    getToExecuteAmount () {
      if (!this.operatorItem.executions.length) {
        return this.operatorItem.quantity
      }

      return this.operatorItem.quantity - this.operatorItem.executions.reduce((sum, item) => sum + item.execution, 0);
    },
    addCommentToMachine(item) {
      this.$bus.$emit('machine-comment-popup-show', item.id, item.machine_name, item.machine_comments);
    },
  },
  beforeDestroy () {
    this.$bus.$off('reload-operator-list', this.loadFilteredData);
  }
};
</script>
