<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">
          Realizacja zamówienia
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/zamowienia')"
          class="mr-3">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="info"
          @click.prevent="
            $bus.$emit(
              'machine-comment-popup-show',
              data.id,
              data.machine_name,
              data.machine_comments
            )"
          title="Uwagi do maszyny"
          class="mr-3">
          Uwagi do maszyny
        </d-button>
        <d-button
          :disabled="getToExecuteAmount() === 0"
          theme="success"
          @click.prevent="
            $bus.$emit(
              'machine-execution-popup-show',
              data.id,
              data.quantity,
              data.quantity - getToExecuteAmount(),
              [],
              data.service.service_name,
              '',
              data.service.product.product_code,
              data.service.product.order.id,
              data.service.product.order.operator_description
            )"
          title="Wprowadź wykonanie">
          Wprowadź wykonanie
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!loaded"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <validation-observer
          ref="order-operator-form"
          tag="form">
          <d-tabs
            card
            :class="{'is-loading': !loaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>
              <div v-if="loaded">
                <d-row>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Maszyna:
                      </label>
                      <div class="text-primary">
                        {{ data.machine_name }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Kod produktu:
                      </label>
                      <div class="text-primary">
                        {{ data.service.product.product_code }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Kolor czapki:
                      </label>
                      <div class="text-primary">
                        {{ data.service.product.color_name }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                     <label>
                        Pozostało do wykonania:
                      </label>
                      <div
                        :class="{
                          'text-danger': getToExecuteAmount() > 0,
                          'text-success': getToExecuteAmount()=== 0,
                          }">
                        {{ getToExecuteAmount() }} szt. (z {{ data.quantity }} szt.)
                      </div>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Zdobienie:
                      </label>
                      <div class="text-primary">
                        {{ data.service.service_name }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Prawdziwy wariant zdobienia:
                      </label>
                      <div class="text-primary">
                        <template v-if="data.service.real_service_name">
                          {{ data.service.real_service_name }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Grupa zdobień:
                      </label>
                      <div class="text-primary">
                        {{ data.service.service_group_name }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Pozycja zdobienia:
                      </label>
                      <div class="text-primary">
                        {{ data.service.embroidery_position }}
                      </div>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Numer DST:
                      </label>
                      <div>
                        <template v-if="getDstNumber()">
                          <span class="text-primary">
                            {{ getDstNumber() }}
                          </span>
                        </template>
                        <template v-else>
                          brak
                        </template>
                      </div>
                    </div>
                  </d-col>
                  <d-col sm="3">
                    <div class="form-group">
                      <label>
                        Liczba ściegów:
                      </label>

                      <div>
                        <template v-if="getStitchNumber()">
                          <span class="text-primary">
                            {{ getStitchNumber() }}
                          </span>
                        </template>
                        <template v-else>
                          brak
                        </template>
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Kolory nici:
                      </label>
                      <div>
                        <template v-if="getStrands()">
                          <span class="text-primary">
                            {{ getStrands() }}
                          </span>
                        </template>
                        <template v-else>
                          brak
                        </template>
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Rozmiar:
                      </label>
                      <div>
                        <template v-if="data.service.product.size_name">
                          <span class="text-primary">
                            {{ data.service.product.size_name }}
                          </span>
                        </template>
                        <template v-else>
                          brak
                        </template>
                      </div>
                    </div>
                  </d-col>
                </d-row>
                <d-row>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        DST:
                      </label>
                      <div class="text-primary">
                        {{ getDstType(data.service.dst) }}
                      </div>
                    </div>
                  </d-col>
                  <d-col md="3">
                    <div class="form-group">
                      <label>
                        Miejsce w magazynie:
                      </label>
                      <template v-if="data.service.product.order.place_in_warehouse">
                        <div class="text-primary">
                          {{ data.service.product.order.place_in_warehouse }}
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          -
                        </div>
                      </template>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col sm="12">
                    <div class="form-group">
                      <label for="description">
                        Uwagi:
                      </label>
                      <div>
                        {{ data.service.description ||'-'}}
                      </div>
                    </div>
                  </d-col>
                </d-row>

                <d-row class="mb-3 pt-3 border-bottom">
                  <d-col sm="12">
                    <h5>Próby zdobienia</h5>
                  </d-col>
                </d-row>

                <template v-if="data.service.samples.length">
                  <order-creator-service-samples-info
                    ref="order-creator-service-samples-info"
                    :samples="data.service.samples" />
                </template>
                <template v-else>
                  Brak prób dla tego zdobienia.
                </template>
              </div>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </validation-observer>
      </d-card>
    </d-form>
    <order-creator-service-machine-execution
      :orderID="null"
      :operatorDescription="null"
      :sourceView="'operatorView'" />
    <order-creator-service-machine-comment
      :sourceView="'operatorView'" />
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';
import ProductTypes from '@/data/product-types.js';
import DstTypes from '@/data/dst-types.js';
import OrderCreatorServiceMachineExecutionPopup from '../popups/OrderCreatorServiceMachineExecutionPopup.vue';
import OrderCreatorServiceMachineCommentPopup from '../popups/OrderCreatorServiceMachineCommentPopup.vue';
import OrderCreatorServiceSamplesInfo from './order/OrderCreatorServiceSamplesInfo.vue';

export default {
  name: 'order-operator-form',
  components: {
    'order-creator-service-machine-execution': OrderCreatorServiceMachineExecutionPopup,
    'order-creator-service-machine-comment': OrderCreatorServiceMachineCommentPopup,
    'order-creator-service-samples-info': OrderCreatorServiceSamplesInfo
  },
  computed: {
    productTypeOptions () {
      return ProductTypes;
    },
    dstOptions () {
      return DstTypes;
    }
  },
  data() {
    return {
      data: {
        executions: 0,
        id: 0,
        machine_comments: [],
        machine_id: 0,
        machine_name: '',
        operator_description: '',
        order_id: 0,
        quantity: 0,
        service: null
      },
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    this.loadItemData();
    this.$bus.$on('reload-order-view', this.loadItemData);
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/orders/machines/operator-item/',
        instanceFields: ['id', 'quantity', 'executions', 'machine_id', 'machine_name', 'service', 'order_id', 'operator_description', 'machine_comments'],
        loadedKey: 'loaded'
      });
    },
    getDstType (dstValue) {
      if (!dstValue) {
        return '-';
      }
      let itemWithValue = this.dstOptions.find(dst => dst.value === dstValue);
      if (itemWithValue && itemWithValue.text) {
        return itemWithValue.text;
      }

      return '-';
    },
    getToExecuteAmount () {
      if (!this.data.executions.length) {
        return this.data.quantity
      }

      return this.data.quantity - this.data.executions.reduce((sum, item) => sum + item.execution, 0);
    },
    getDstNumber () {
      let mostRecentDstNumber = this.data.service.dst_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = this.data.service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].dst_number) {
            mostRecentDstNumber = acceptedSamples[i].dst_number;
          }
        }
      }
      return mostRecentDstNumber;
    },
    getStitchNumber () {
      let mostRecentStitchNumber = this.data.service.stitch_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = this.data.service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].stitch_number) {
            mostRecentStitchNumber = acceptedSamples[i].stitch_number;
          }
        }
      }
      return mostRecentStitchNumber;
    },
    getStrands () {
      let mostRecentStrands = this.data.service.strands;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = this.data.service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].strands && acceptedSamples[i].strands.length) {
            mostRecentStrands = acceptedSamples[i].strands;
          }
        }
      }

      let strandsString = '';
      for (let i = 0; i < mostRecentStrands.length; i++) {
        strandsString += mostRecentStrands[i].strand_name + ';  ';
      }

      return strandsString;
    }
  },
  beforeDestroy () {
    this.$bus.$off('reload-order-view', this.loadItemData);
  }
};
</script>
