var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-creator-service-samples-info"},[(_vm.dataLoaded)?_c('div',_vm._l((_vm.samples),function(sample,index){return _c('d-container',{key:'sample-item-' + index,class:{
        'mt-4': true,
        'mb-0': true,
        'mx-0': true,
        'pt-0': true,
        'pb-0': true,
        'px-0': true,
        'w-100': true,
        'sample-item': true,
        'bg-light': index%2 === 0
      },staticStyle:{"border-radius":"0.625rem","max-width":"unset"}},[_c('d-link',{directives:[{name:"d-toggle",rawName:"v-d-toggle",value:('sample-' + index + '-collapse'),expression:"'sample-' + index + '-collapse'"}],staticClass:"dropdown-toggle"},[_c('d-row',{staticClass:"pl-4 py-2 border-bottom mx-0 my-0",staticStyle:{"background":"#F5F6F8","border-radius":"0.625rem 0.625rem 0 0"}},[_c('h6',{staticClass:"mb-0 d-flex align-items-center flex-wrap",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"pr-1 col-sm-12 col-md-4"},[_vm._v("Próba z dnia "+_vm._s(_vm.prepareDateString(_vm.samples[index].created_at)))]),(_vm.samples[index].name)?_c('span',{staticClass:"pr-1 col-sm-12 col-md-4"},[_vm._v(" Nazwa: "+_vm._s(_vm.samples[index].name)+" ")]):_vm._e(),( _vm.samples[index].status)?_c('span',{staticClass:"col-sm-12 col-md-4"},[_vm._v(" Status: "),_c('span',{class:{
                  'text-success': sample.status === 'APPROVED',
                  'text-warning': sample.status === 'WAITING',
                  'text-danger': sample.status === 'REJECTED'
                }},[_vm._v(" "+_vm._s(_vm.statusOptions.find( item => item.value === _vm.samples[index].status).text)+" ")])]):_vm._e()])])],1),_c('d-collapse',{attrs:{"visible":"","id":'sample-' + index + '-collapse'}},[_c('div',{staticClass:"d-flex pt-2 pb-3 px-2 align-items-start flex-wrap"},[_c('h6',{staticClass:"pl-3 pt-2 d-block"},[_vm._v("Zdjęcia podglądów:")]),(sample.images.length)?_c('div',{staticClass:"w-100 pt-3 px-0 d-flex flex-wrap",staticStyle:{"background":"#fff"}},_vm._l((sample.images),function(image,imgIndex){return _c('img',{key:'sample-item-' + index + '-image' + imgIndex,staticClass:"mb-3 mx-3",staticStyle:{"object-fit":"contain","cursor":"pointer"},attrs:{"src":image.file_url,"height":"150","width":"150","alt":image.file},on:{"click":_vm.showImgFullScreen}})}),0):(!sample.images.length)?_c('div',{staticClass:"w-100 text-center"},[_vm._v(" Brak zdjęć dla tej próby... ")]):_c('div',{staticClass:"w-100 pl-3 text-center"},[_vm._v(" Trwa wczytywanie zdjęć... ")])])])],1)}),1):_vm._e(),(!_vm.dataLoaded)?_c('div',{staticClass:"pb-3 pt-3 text-center"},[_vm._v(" Trwa ładowanie danych… ")]):_vm._e(),_c('d-modal',{class:{ 'is-hidden': !_vm.showImgFull, 'imageModal': true },attrs:{"size":"lg"},on:{"close":_vm.handleImgClose}},[_c('d-modal-header',[_vm._v(" Zdjęcie próby ")]),_c('d-modal-body',[_c('img',{staticClass:"d-block mx-auto",staticStyle:{"object-fit":"contain","cursor":"pointer","max-width":"100%"},attrs:{"src":_vm.imageFullUrl}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }