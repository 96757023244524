<template>
  <d-container
    fluid
    class="main-content-container exact-height px-0 px-md-2 px-lg-4 pb-3 schedules-view">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="9"
        lg="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Harmonogram</span>
        <h3 class="page-title">Harmonogram zamówień</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="3"
        lg="3"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          v-if="!isOperator"
          theme="success"
          class="text-nowrap ml-auto ml-md-3 mr-1 mb-2"
          @click.prevent="saveSchedule"
          :disabled="filtersInProgress">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%"
      class="d-flex ml-0 mb-3 page-filters">
      <validation-observer
        ref="schedule-filters-form"
        tag="form"
        style="width: 100%"
        class="d-flex mb-2">
        <d-input
          placeholder="Nazwa/ID zamówienia"
          v-model="filters.search"
          class="mr-1 mb-2 mt-auto"
          :disabled="filtersInProgress"/>
        <v-select
          style="min-width: 17%;"
          class="mr-1 mb-2 mt-auto"
          :options="stateFilterOptions"
          v-model="filters.scheduleItemState"
          :reduce="item => item.value"
          placeholder="Stan planu"
          :searchable="false"
          label="text"
          :disabled="filtersInProgress">
        </v-select>

        <v-select
          v-if="!isOperator"
          style="min-width: 17%;"
          class="mr-1 mb-2 mt-auto"
          :options="allOperators"
          v-model="filters.operator"
          :reduce="item => item.id"
          placeholder="Operator"
          :searchable="true"
          label="name"
          :disabled="filtersInProgress">
        </v-select>

        <v-select
          v-if="isAdmin"
          style="min-width: 17%;"
          class="mr-1 mb-2 mt-auto"
          :options="allMachines"
          v-model="filters.machineID"
          :reduce="item => item.id"
          placeholder="Maszyna"
          :searchable="true"
          label="name"
          :disabled="filtersInProgress">
        </v-select>

        <div class="w-100 mb-2" style="max-width: 220px;min-width: 200px;">
          <label for="dateStart" class="mb-0">
            <small>Plan mieści się w datach:</small>
          </label>
          <div
            :class="{
              'form-control is-date-range vs-wrap-text w-100 text-center': true,
              'is-disabled': filtersInProgress
            }">
            <date-range-picker
              ref="picker"
              :locale-data="{
                'firstDay': 1,
                'format': 'DD-MM-YYYY',
                'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                'applyLabel': 'OK',
                'cancelLabel': 'Anuluj'
              }"
              style="width: auto;"
              :singleDatePicker="false"
              :timePicker="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              v-model="dateRange"
              :ranges="false"
              :linkedCalendars="true"
              :auto-apply="true"
              :disabled="filtersInProgress">
              <template v-slot:input="picker">
                {{ picker.startDate | dateStart }} - {{ picker.endDate | dateEnd }}
              </template>
            </date-range-picker>
          </div>
        </div>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-auto ml-md-3 mr-1 mb-2 mt-auto"
          :disabled="filtersInProgress"
          @click.prevent="validateFiltersForm">
          Szukaj
        </d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2 mt-auto"
          :disabled="filtersInProgress"
          @click.prevent="clearFilters">
          Resetuj filtry
        </d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%">
      <div class="schedule-controls-wrapper my-4 position-relative">
        <div
          v-if="allDataLoaded"
          class="schedule-controls">
          <d-button
            outline
            theme="primary"
            @click.prevent="scrollToFirstDay"
            class="ml-3"
            :title="'Pokaż początek'"
            :disabled="!isScrollPrevEnabled || isListEmpty">
            <i class="material-icons">keyboard_arrow_left</i>
            Pokaż początek
          </d-button>

          <d-button
            outline
            theme="primary"
            @click.prevent="scrollToPrevDay"
            class="ml-auto"
            :title="'Poprzedni dzień'"
            :disabled="!isScrollPrevEnabled || isListEmpty">
            <i class="material-icons ml-1">keyboard_arrow_left</i>
          </d-button>

          <d-button
            outline
            theme="primary"
            @click.prevent="scrollToToday"
            class="mx-5"
            :title="'Pokaż dzisiaj'"
            :disabled="todayIndex < 0 || isListEmpty">
            Pokaż dzisiaj
          </d-button>

          <d-button
            outline
            theme="primary"
            @click.prevent="scrollToNextDay"
            class="mr-auto"
            :title="'Następny dzień'"
            :disabled="!isScrollNextEnabled || isListEmpty">
            <i class="material-icons ml-1">keyboard_arrow_right</i>
          </d-button>

          <d-button
            outline
            theme="primary"
            @click.prevent="scrollToLastDay"
            class="mr-3"
            :title="'Pokaż koniec'"
            :disabled="!isScrollNextEnabled || isListEmpty">
            Pokaż koniec
            <i class="material-icons ml-1">keyboard_arrow_right</i>
          </d-button>
        </div>
      </div>
      <template v-if="allDataLoaded">
        <p
          v-if="isListEmpty"
          class="my-4 text-center">Brak elementów do wyświetlenia&hellip;
        </p>
        <div
          v-show="!isListEmpty"
          ref="schedule-table-wrapper"
          class="table-responsive table-wrapper"
          v-on:scroll="handleScroll">
          <table
            ref="schedule-table"
            class="table schedule-table table-hover mt-0 mb-0 full-width">
            <thead class="bg-light">
              <tr>
                <th
                  ref="thead-first-col"
                  scope="col"
                  class="border-left-0 border-bottom-0 border-top-0 border-right align-middle bg-light service-name-col"
                  rowspan="2">
                  Zamówienie / <span class="text-primary">Zdobienie</span>
                </th>
                <th
                  v-for="(day, dayIndex) in visibleDaysInSchedule"
                  :key="'day-' + dayIndex"
                  :ref="'thead-day-' + dayIndex"
                  scope="col"
                  :colspan="day.machinesVisible.length"
                  :class="{
                    'border-left-0 border-top-0 border-bottom-0 border-right align-middle text-center bg-light text-primary': true,
                    'day-even': dayIndex % 2 === 0,
                    'day-odd': dayIndex % 2 !== 0
                  }">
                  {{ day.date }}
                </th>
              </tr>
              <tr>
                <template v-for="(day, dayIndex) in visibleDaysInSchedule">
                  <th
                    v-for="(machine, machineIndex) in filteredMachines.filter(item => day.machinesVisible.indexOf(item.id) > -1)"
                    :key="`machine-${machineIndex}-day-${dayIndex}-${machinesForDaysTime[`${day.date}-${machine.id}`]}`"
                    :ref="'thead-day-' + dayIndex + '-machine-' + machineIndex"
                    scope="col"
                    :class="{
                      'border-left-0 border-bottom-0 border-top-0 border-right align-middle text-center machine-name-col': true,
                      'table-danger': !isOperator && (machinesForDaysTime[day.date + '-' + machine.id] && machinesForDaysTime[day.date + '-' + machine.id] > machine.day_working_time),
                      'day-even': dayIndex % 2 === 0,
                      'day-odd': dayIndex % 2 !== 0
                    }" >
                    {{ machine.nameBase }}<br>
                    <small>{{machine.nameDetails }}</small>
                    <div
                      v-if="!isOperator"
                      class="details">
                      <small>Max: </small>
                      <small class="text-primary">{{ machine.day_working_time}} min</small>
                      <br>
                      <small>Zajęte: </small>
                      <small class="text-primary">{{ machinesForDaysTime[day.date + '-' + machine.id] || 0 }} min</small>
                    </div>
                    <div
                      v-if="!isOperator"
                      class="details-column mt-2">
                      <small
                        v-for="(operator, operatorIndex) in getOperatorsForMachineOnDay(machine.id, day.date)"
                        :key="'operator-' + operatorIndex"
                        class="font-weight-bold">
                        {{ operator }}
                      </small>
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
            <draggable
              :list="schedulesList"
              tag="tbody"
              @end="onEnd">
              <schedule-item
                v-for="(schedule, index) of schedulesList"
                :key="`schedule-${index}-${schedule.uuid || 0}`"
                :ref="'schedule-item-' + index"
                :all-machines.sync="allMachines"
                :days-in-schedule="visibleDaysInSchedule"
                :filters="filtersAccepted"
                :filtered-machines="filteredMachines"
                :is-readonly="isOperator"
                :item-index="index"
                :machines-for-days-time="machinesForDaysTime"
                :product-type-options="productTypeOptions"
                :schedule="schedule" />
            </draggable>
          </table>
        </div>
      </template>

      <div
        v-if="filtersInProgress || !allDataLoaded"
        class="overlay-loading" >
        <div class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
      </div>

      <d-alert v-if="loadError" show theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>
    <schedule-item-popup
      v-if="allDataLoaded && !isOperator"
      :all-machines="allMachines"
      :machines-for-days-time="machinesForDaysTime"
      :max-date="maxDateExtended"
      :min-date="minDate" />
  </d-container>
</template>

<script>
import Vue from 'vue';
import vueDraggable from 'vuedraggable';
import FormUtils from '@/utils/FormUtils.js';
import ScheduleItem from '@/components/forms/schedule/ScheduleItem.vue';
import ScheduleItemPopup from '@/components/popups/ScheduleItemPopup.vue';
import ProductTypes from '@/data/product-types.js';
import CustomDecimals from '@/utils/CustomDecimals.js';
import HasFilters from '@/mixins/HasFilters.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CalendarUtils from '@/utils/CalendarUtils.js';

export default {
  name: 'schedule2',
  mixins: [HasFilters],
  components: {
    'draggable': vueDraggable,
    'schedule-item': ScheduleItem,
    'schedule-item-popup': ScheduleItemPopup,
    'date-range-picker': DateRangePicker
  },
  filters: {
    dateStart (input) {
      if (input === null) {
        return ' szukaj od ';
      }

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    dateEnd (input) {
      if (input === null) {
        return ' do ';
      }

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    }
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.machinesLoaded && this.dataIsSet;
    },
    isPreviousDayAvailable () {
      return true;
    },
    isNextDayAvailable () {
      return true;
    },
    todayIndex () {
      if (!this.allDataLoaded) {
        return -1;
      }
      let currentDate = new Date();
      return this.daysInSchedule.findIndex(item => item.dateObj.getFullYear() === currentDate.getFullYear() && item.dateObj.getMonth() === currentDate.getMonth() && item.dateObj.getDate() === currentDate.getDate());
    },
    productTypeOptions () {
      return ProductTypes;
    },
    scheduleDayWidth () {
      if (this.isListEmpty || !this.scheduleWidth || !this.$refs['thead-day-0'] || !this.$refs['thead-day-0'].length) {
        return 0;
      }

      return this.$refs['thead-day-0'][0].offsetWidth;
    },
    scheduleTableWrapper () {
      if (!this.scheduleWidth) {
        return null;
      }

      return this.$refs['schedule-table-wrapper'];
    },
    scheduleTableMaxScrollX () {
      if (!this.scheduleWidth || !this.scheduleTableWrapper) {
        return 0;
      }

      return this.scheduleTableWrapper.scrollWidth - this.scheduleTableWrapper.clientWidth;
    },
    isScrollPrevEnabled () {
      if (!this.scheduleWidth || !this.scheduleDayWidth) {
        return false;
      }

      if (this.scheduleTableWrapperScrollPosX === 0) {
        return false;
      }
      return true;
    },
    isScrollNextEnabled () {
      if (!this.scheduleWidth || !this.scheduleDayWidth) {
        return false;
      }

      if (this.scheduleTableWrapperScrollPosX >= this.scheduleTableMaxScrollX) {
        return false;
      }
      return true;
    },
    allFilteredMachinesIds () {
      return this.filteredMachines.map(item => item.id);
    },
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    },
    isOperator () {
      return this.$store.getters['getUserType'] === 'OPERATOR';
    },
    operatorId () {
      return this.$store.getters['getUserID'];
    },
    visibleDaysInSchedule () {
      return this.daysInSchedule.filter(item => item.isVisible);
    },
    isListEmpty () {
      if (!this.schedulesList) {
        return false;
      }
      return !this.schedulesList.length || this.isVisibleListEmpty;
    },
    filteredMachines () {
      if (!this.isAdmin || !this.filtersAccepted.machineID) {
        return this.allMachines;
      }

      return this.allMachines.filter(item => item.id === this.filtersAccepted.machineID);
    }
  },
  watch: {
    allDataLoaded (newValue) {
      if (!newValue) {
        return;
      }

      this.initMachinesForDaysTime();

      Vue.nextTick(() => {
        console.log(`Rendering took ${performance.now() - this.startTime} milliseconds.`);
        this.setScheduleTableOffsetWidth();
      })
    },
    'dateRange.startDate': function (newValue) {
      this.isDefaultDateRange = this.getIsSameDate(this.dateRange.startDate, this.minDate) && this.getIsSameDate(this.dateRange.endDate, this.maxDateExtended);

      if (newValue === null) {
        this.filters.dateFrom = null;

        if (this.dateRange.endDate === null) {
          this.isDefaultDateRange = true;
        }
      }

      this.filters.dateFrom = this.prepareDateString(newValue);
    },
    'dateRange.endDate': function (newValue) {
      this.isDefaultDateRange = this.getIsSameDate(this.dateRange.startDate, this.minDate) && this.getIsSameDate(this.dateRange.endDate, this.maxDateExtended);

      if (newValue === null) {
        this.filters.dateTo = null;

        if (this.dateRange.startDate === null) {
          this.isDefaultDateRange = true;
        }
      }

      this.filters.dateTo = this.prepareDateString(newValue);
    },
    filtersInProgress (newValue) {
      if (newValue) {
        return;
      }

      Vue.nextTick(() => {
        console.log(`(f) Rendering took ${performance.now() - this.startTime} milliseconds.`);
        this.setScheduleTableOffsetWidth();
      })
    }
  },
  data () {
    return {
      allMachines: [],
      allOperators: [],
      dataIsSet: false,
      dateRange: {
        endDate: null,
        startDate: null
      },
      daysInSchedule: [],
      filters: {
        dateFrom: null,
        dateTo: null,
        machineID: null,
        operator: null,
        scheduleItemState: null,
        search: ''
      },
      filtersAccepted: {
        dateFrom: null,
        dateTo: null,
        machineID: null,
        operator: null,
        scheduleItemState: null,
        search: ''
      },
      filtersInProgress: false,
      isDefaultDateRange: true,
      isVisibleListEmpty: false,
      loaded: false,
      loadError: false,
      machinesForDaysTime: {},
      machinesLoaded: false,
      maxDate: null,
      maxDateExtended: null,
      minDate: null,
      operatorsDates: [],
      operatorsLoaded: false,
      schedulesList: [],
      scheduleTableWrapperScrollPosX: 0,
      scheduleWidth: 0,
      startTime: null,
      stateFilterOptions: [{
        text: 'Zaplanowane w 100%',
        value: 'isPlanned'
      }, {
        text: 'Z błędami',
        value: 'withErrors'
      }]
    };
  },
  mounted () {
    this.startTime = performance.now();
    this.$bus.$on('execution-updated', this.updateMachinesForDaysTime);
    this.$bus.$on('schedule-items-add', this.addScheduleItem);
    this.$bus.$on('schedule-items-modify', this.modifyScheduleItem);
    this.$bus.$on('scroll-to-day', this.scrollToDay);

    this.loadSchedule();
    this.loadAdditionalData();
    if (this.$store.getters['getUserType'] === 'OFFICE' || this.$store.getters['getUserType'] === 'ADMIN') {
      this.$bus.$emit('update-schedules-without-date-count');
      this.stateFilterOptions.push({
        text: 'Z planem bez daty',
        value: 'withoutDates'
      })
    }
  },
  methods: {
    loadSchedule () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/orders/schedule/v2/items',
        method: 'get',
        loadedKey: 'loaded',
        errorKey: 'loadError',
        noPagination: true,
        successAction: (response) => {
          this.schedulesList = response.data;
          this.maxDate = new Date(response.max_date);
          this.minDate = new Date(response.min_date);
          this.operatorsDates = response.operator_dates;

          if (CalendarUtils.getIsDayBeforeDate(this.maxDate, new Date())) {
            this.maxDate = new Date();
          }
          this.maxDateExtended = new Date(this.maxDate);
          // add extra 14 days
          this.maxDateExtended.setDate(this.maxDateExtended.getDate() + 14);

          this.setDateRangeState();
          this.filterSchedule(true);

          this.dataIsSet = true;
        }
      });
    },
    loadAdditionalData () {
      if (!this.isOperator) {
        FormUtils.loadAdditionalData(this, {
          endpoint: '/api/users/items',
          method: 'get',
          outputKey: 'allOperators',
          loadedKey: 'operatorsLoaded',
          errorKey: 'loadError',
          params: {
            pagination: 0,
            where: {
              active: 1,
              type: 'OPERATOR'
            }
          }
        });
      } else {
        this.operatorsLoaded = true;
        this.filters.operator = this.operatorId;
        this.filters.filtersAccepted = this.operatorId;
      }

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'machinesLoaded',
        errorKey: 'loadError',
        params: {
          pagination: 0
        },
        successAction: () => {
          this.formatMachinesNames();
        }
      });
    },
    saveSchedule () {
      if (!this.filtersAccepted.search && !this.filtersAccepted.dateFrom && !this.filtersAccepted.dateTo && !this.filtersAccepted.scheduleItemState && !this.filtersAccepted.machineID) {
        this.submitSchedule();
        return;
      }

      Vue.swal({
        title: 'Czy na pewno chcesz zapisać harmonogram',
        text: 'Zapisany zostanie cały harmonogram łącznie z elementami ukrytymi za pomocą filtrów.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, zapisz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.submitSchedule();
        }
      });
    },
    submitSchedule () {
      FormUtils.submit(this, {
        endpoint: '/api/orders/schedule/v2/save',
        formData: { items: this.schedulesList },
        successTitle: 'Zapisano harmonogram',
        successText: 'Zmiany w harmonogramie zostały zapisane.',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Zapis nie powiódł się. Spróbuj ponownie.',
        successAction: () => {
          this.loaded = false;
          this.loadSchedule();
          this.$bus.$emit('update-schedules-without-date-count');
        }
      });
    },
    prepareDateString (date) {
      date = new Date(date);

      return [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    formatMachinesNames () {
      // belows is just for text formatting of machine names
      for (let i = 0; i < this.allMachines.length; i++) {
        let nameSplit = this.allMachines[i].name.split('-');
        this.allMachines[i].nameBase = nameSplit[0].trim();
        this.allMachines[i].nameDetails = nameSplit[1].trim();
      }
    },
    scrollToToday () {
      let refToScrollTo = this.$refs['thead-day-' + this.todayIndex];
      if (!refToScrollTo) {
        return;
      }

      refToScrollTo[0].scrollIntoView({ behavior: 'smooth', inline: 'end' });
    },
    scrollToLastDay () {
      let refToScrollTo = this.$refs['thead-day-' + (this.daysInSchedule.length - 1)];
      if (!refToScrollTo) {
        return;
      }

      refToScrollTo[0].scrollIntoView({ behavior: 'smooth', inline: 'start' });
    },
    scrollToFirstDay () {
      let refToScrollTo = this.$refs['thead-day-0'];
      if (!refToScrollTo) {
        return;
      }

      refToScrollTo[0].scrollIntoView({ behavior: 'smooth', inline: 'end' });
    },
    scrollToPrevDay () {
      if (this.scheduleTableWrapper.scrollLeft <= 0) {
        return;
      }

      let scrollToVal = 0;

      if ((this.scheduleTableWrapper.scrollLeft % this.scheduleDayWidth) === 0) {
        scrollToVal = this.scheduleTableWrapper.scrollLeft - this.scheduleDayWidth;
      } else {
        scrollToVal = this.scheduleDayWidth * Math.floor(this.scheduleTableWrapper.scrollLeft / this.scheduleDayWidth);
      }

      this.scheduleTableWrapper.scroll({
        behavior: 'smooth',
        left: scrollToVal,
        top: this.scheduleTableWrapper.scrollTop
      })
    },
    scrollToNextDay () {
      if (this.scheduleTableWrapper.scrollLeft >= this.scheduleWidth - this.scheduleDayWidth) {
        return;
      }

      let scrollToVal = 0;

      if ((this.scheduleTableWrapper.scrollLeft % this.scheduleDayWidth) === 0) {
        scrollToVal = this.scheduleTableWrapper.scrollLeft + this.scheduleDayWidth;
      } else {
        scrollToVal = this.scheduleDayWidth * Math.ceil(this.scheduleTableWrapper.scrollLeft / this.scheduleDayWidth);
      }

      this.scheduleTableWrapper.scroll({
        behavior: 'smooth',
        left: scrollToVal,
        top: this.scheduleTableWrapper.scrollTop
      })
    },
    scrollToDay (date, machineID) {
      let machineIndex = this.allMachines.findIndex(item => item.id === machineID);
      let dayIndex = this.daysInSchedule.findIndex(item => item.date === date);
      let refToScrollTo = this.$refs['thead-day-' + dayIndex + '-machine-' + machineIndex];

      if (!refToScrollTo) {
        return;
      }

      refToScrollTo[0].scrollIntoView({ behavior: 'smooth', inline: 'end' });
    },
    handleScroll () {
      this.scheduleTableWrapperScrollPosX = this.scheduleTableWrapper.scrollLeft;
    },
    updateMachinesForDaysTime (machineDayKey, newValue, oldValue = 0) {
      if (!(machineDayKey in this.machinesForDaysTime)) {
        Vue.set(this.machinesForDaysTime, machineDayKey, Number(this.roundProperly(newValue, 2)));
        return;
      }

      let newMinutesCount = Number(this.roundProperly((this.machinesForDaysTime[machineDayKey] - oldValue) + newValue, 2));
      Vue.set(this.machinesForDaysTime, machineDayKey, newMinutesCount);
    },
    addScheduleItem (scheduleIndex, newPlan) {
      this.schedulesList[scheduleIndex].items.push(newPlan);
    },
    modifyScheduleItem (scheduleIndex, newPlan) {
      let originalPlan = this.schedulesList[scheduleIndex].items.find(item => item.id === newPlan.id);
      if (!originalPlan) {
        console.warn('could not find schedule item to modify');
        return;
      }

      Object.assign(originalPlan, newPlan);
      originalPlan.planned_quantity = Number(originalPlan.planned_quantity);
      Vue.delete(originalPlan, 'edit_mode');
    },
    roundProperly (value, decimals = 3) {
      return CustomDecimals.roundProperly(value, decimals);
    },
    getOperatorsForMachineOnDay (machineID, date) {
      let output = [];
      let entryForDate = this.operatorsDates.find(item => item.date === date);
      if (!entryForDate) {
        return output;
      }

      let operatorsForMachine = entryForDate.operators.filter(item => item.machine_id === machineID);

      if (!operatorsForMachine || !operatorsForMachine.length) {
        return output;
      }

      for (let i = 0; i < operatorsForMachine.length; i++) {
        output.push(operatorsForMachine[i].operator_name);
      }

      return output;
    },
    clearFilters() {
      Vue.set(this, 'filtersInProgress', true);

      setTimeout(() => {
        this.filters = {
          dateFrom: null,
          dateTo: null,
          machineID: null,
          operator: this.isOperator ? this.operatorId : null,
          scheduleItemState: null,
          search: ''
        };

        this.filtersAccepted = {
          dateFrom: null,
          dateTo: null,
          machineID: null,
          operator: this.isOperator ? this.operatorId : null,
          scheduleItemState: null,
          search: ''
        };

        this.setDateRangeState();
        this.generateDaysInSchedule();
        this.$bus.$emit('view-filters-reset');

        Vue.nextTick(() => {
          Vue.set(this, 'filtersInProgress', false);
        })
      }, 50)
    },
    validateFiltersForm() {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(
        this.$refs['schedule-filters-form'],
        this.filterSchedule
      );
    },
    filterSchedule (isReload = false) {
      Vue.set(this, 'filtersInProgress', true);

      setTimeout(() => {
        this.filtersAccepted.search = this.filters.search.toLowerCase();
        this.filtersAccepted.scheduleItemState = this.filters.scheduleItemState;
        this.filtersAccepted.operator = this.filters.operator;
        this.filtersAccepted.machineID = this.filters.machineID;

        if (this.isDefaultDateRange) {
          this.filtersAccepted.dateFrom = null;
          this.filtersAccepted.dateTo = null;
        } else {
          this.filtersAccepted.dateFrom = new Date(this.filters.dateFrom);
          this.filtersAccepted.dateTo = new Date(this.filters.dateTo);
        }

        this.$bus.$emit('view-filters-save');
        this.generateDaysInSchedule(isReload);

        Vue.nextTick(() => {
          Vue.set(this, 'filtersInProgress', false);
        })
      }, 50)
    },
    setDateRangeState () {
      this.dateRange.startDate = this.filters.dateFrom ? new Date(this.filters.dateFrom) : new Date(this.minDate);
      this.dateRange.endDate = this.filters.dateTo ? new Date(this.filters.dateTo) : this.maxDateExtended;
    },
    getIsSameDate (date1, date2) {
      return CalendarUtils.getIsSameDay(date1, date2);
    },
    getIsDayVisible (dayDate, dateString, visibleMachinesForDay) {
      if (!dayDate) {
        return true;
      }

      let minDate = this.filtersAccepted.dateFrom || this.minDate;
      let maxDate = this.filtersAccepted.dateTo || this.maxDateExtended;

      let isDayVisible = CalendarUtils.getIsDateInRange(dayDate, minDate, maxDate);

      if (!this.isOperator || !isDayVisible) {
        return isDayVisible;
      }

      // when in operator's panel show only items with plan for this operator
      return !!(this.schedulesList.find(scheduleItem => scheduleItem.items.find(item => item.date === dateString && visibleMachinesForDay.indexOf(item.machine_id) > -1)));
    },
    getVisibleMachinesForDay (date) {
      if (!this.filtersAccepted.operator || this.isOperator) {
        return this.allFilteredMachinesIds;
      }

      let operatorsForDay = this.operatorsDates.find(item => item.date === date);

      if (!operatorsForDay) {
        return [];
      }

      let machinesForOperator = operatorsForDay.operators.filter(item => item.operator_id === this.filtersAccepted.operator);
      if (!machinesForOperator || !machinesForOperator.length) {
        return [];
      }

      return machinesForOperator.map(item => item.machine_id);
    },
    generateDaysInSchedule (isReload = false) {
      if (isReload || !this.daysInSchedule.length) {
        let outputArray = [];
        let dateToAdd = new Date(this.minDate);

        while (dateToAdd <= this.maxDateExtended) {
          let dateObject = new Date(dateToAdd);
          let dateString = this.prepareDateString(dateToAdd);
          let visibleMachinesForDay = this.getVisibleMachinesForDay(dateString);
          let isDayVisible = !!visibleMachinesForDay.length;

          if (isDayVisible) {
            isDayVisible = this.getIsDayVisible(dateObject, dateString, visibleMachinesForDay);
          }

          outputArray.push({
            date: dateString,
            dateObj: dateObject,
            isVisible: isDayVisible,
            machinesVisible: visibleMachinesForDay
          });
          dateToAdd.setDate(dateToAdd.getDate() + 1);
        }

        Vue.set(this, 'daysInSchedule', outputArray);

        Vue.nextTick(() => {
          this.setIsVisibleListEmpty();
        })

        return;
      }

      let copy = [...this.daysInSchedule];
      copy.map(item => {
        let visibleMachinesForDay = this.getVisibleMachinesForDay(item.date);
        let isDayVisible = !!visibleMachinesForDay.length;

        if (isDayVisible) {
          isDayVisible = this.getIsDayVisible(item.dateObj, item.date, item.machinesVisible);
        }

        item.isVisible = isDayVisible;
        item.machinesVisible = visibleMachinesForDay;
        return item;
      })

      Vue.set(this, 'daysInSchedule', copy);

      Vue.nextTick(() => {
        this.setIsVisibleListEmpty();
        this.setScheduleTableOffsetWidth();
      })
    },
    setIsVisibleListEmpty () {
      for (let i = 0; i < this.schedulesList.length; i++) {
        if (this.$refs['schedule-item-' + i] && this.$refs['schedule-item-' + i][0] && this.$refs['schedule-item-' + i][0].isItemVisible) {
          Vue.set(this, 'isVisibleListEmpty', false);
          return;
        }
      }

      Vue.set(this, 'isVisibleListEmpty', true);
    },
    setScheduleTableOffsetWidth () {
      if (!this.$refs['schedule-table']) {
        Vue.set(this, 'scheduleWidth', 0);
        return;
      }

      Vue.set(this, 'scheduleWidth', this.$refs['schedule-table'].offsetWidth || 0);
    },
    initMachinesForDaysTime () {
      let output = {};

      for (let i = 0; i < this.schedulesList.length; i++) {
        let serviceInSchedule = this.schedulesList[i];

        for (let j = 0; j < serviceInSchedule.items.length; j++) {
          let schedulePlan = serviceInSchedule.items[j];

          let machineDayKey = `${schedulePlan.date}-${schedulePlan.machine_id}`;
          let machine = this.allMachines.find(item => item.id === schedulePlan.machine_id);
          let samplePreparationTimeForMachine = serviceInSchedule.sample_preparation_time / machine.heads_number;
          let timeOccupied = Number(this.roundProperly(schedulePlan.planned_quantity * samplePreparationTimeForMachine, 2));

          if (!(machineDayKey in output)) {
            output[machineDayKey] = timeOccupied;
          } else {
            output[machineDayKey] += timeOccupied;
          }
        }
      }

      this.machinesForDaysTime = JSON.parse(JSON.stringify(output));
    },
    generateUUID () {
      // eslint-disable-next-line no-restricted-globals
      if (self.crypto) {
        // eslint-disable-next-line no-restricted-globals
        return self.crypto.randomUUID();
      }

      // For older browsers
      let dt = new Date().getTime();
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise, no-mixed-operators
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        // eslint-disable-next-line no-bitwise, no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    onEnd (dragEvent) {
      Vue.set(this.schedulesList[dragEvent.newIndex], 'uuid', this.generateUUID());
      Vue.set(this.schedulesList[dragEvent.oldIndex], 'uuid', this.generateUUID());
    }
  },
  beforeDestroy () {
    this.$bus.$off('execution-updated', this.updateMachinesForDaysTime);
    this.$bus.$off('schedule-items-add', this.addScheduleItem);
    this.$bus.$off('schedule-items-modify', this.modifyScheduleItem);
    this.$bus.$off('scroll-to-day', this.scrollToDay);
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/schedule.scss';
</style>
