import Vue from 'vue';
import jsonToFormData from 'json-form-data';
import qs from 'qs';

export default class FormUtils {
  static loadItemData (instance, config) {
    if (!config.id) {
      instance.id = parseInt(instance.$route.params.id, 10);
    } else {
      instance.id = parseInt(config.id, 10);
    }

    if (config.id === null) {
      instance.id = '';
    }

    let requestMethod = 'post';
    let parameters = config.params || {};

    if (config.method) {
      requestMethod = config.method;
    }

    if (config.method === 'get') {
      parameters = {
        params: parameters,
        paramsSerializer: params => qs.stringify(params)
      };
    }

    instance.$http[requestMethod](config.endpointBase + instance.id, parameters, {
      withCredentials: true
    }).then(response => {
      if (response.data) {
        for (let i = 0; i < config.instanceFields.length; i++) {
          let fieldName = config.instanceFields[i];

          if (config.booleanFields && config.booleanFields.indexOf(fieldName) !== -1) {
            instance.data[fieldName] = !!response.data.data[fieldName];
          } else {
            instance.data[fieldName] = response.data.data[fieldName];
          }
        }

        if (config.loadedKey) {
          instance[config.loadedKey] = true;
        }

        if (config.successAction) {
          config.successAction(response.data.data);
        }
      } else {
        // eslint-disable-next-line
        if (config.errorKey) {
          instance[config.errorKey] = true;
        } else {
          instance.loadError = true;
        }
      }
    }).catch(error => {
      console.log(error);

      if (config.errorKey) {
        instance[config.errorKey] = true;
      } else {
        instance.loadError = true;
      }
    });
  }

  static loadAdditionalData (instance, config) {
    let errorKey = 'loadError';

    if (config.errorKey) {
      errorKey = config.errorKey;
    }

    let requestMethod = 'post';
    let parameters = config.params || {};

    if (config.method) {
      requestMethod = config.method;
    }

    if (config.method === 'get') {
      parameters = {
        params: parameters,
        paramsSerializer: params => qs.stringify(params)
      };
    }

    instance.$http[requestMethod](config.endpoint, parameters, {
      withCredentials: true
    }).then(response => {
      if (!response.data) {
        instance[errorKey] = true;
        return;
      }

      let responseData;

      if (config.noPagination) {
        // eslint-disable-next-line
        if (config.responseKey) {
          responseData = response.data[config.responseKey];
        } else {
          responseData = response.data;
        }
      } else {
        // eslint-disable-next-line
        if (config.responseKey) {
          responseData = response.data.data[config.responseKey];
        } else {
          responseData = response.data.data;
        }
      }

      if (config.wrapResponseIntoArray) {
        responseData = [responseData];
      }

      if (config.outputKeyIndex) {
        instance[config.outputKey][config.outputKeyIndex] = responseData;
      } else {
        instance[config.outputKey] = responseData;
      }

      instance[config.loadedKey] = true;

      if (config.successAction) {
        config.successAction(responseData);
      }
    }).catch(error => {
      console.log(error);
      instance[errorKey] = true;
    });
  }

  static validate (formRef, successAction) {
    formRef.validate().then(result => {
      if (result) {
        successAction();
      }
    });
  }

  static submit (instance, config, imageFields = false) {
    if (!imageFields || imageFields.length === 0) {
      instance.$http.post(
        config.endpoint,
        config.formData,
        {
          withCredentials: true
        }
      ).then(response => {
        if (response.data.success) {
          FormUtils.submitShowSuccess(config, response);
        } else {
          FormUtils.submitShowError(config, response);
        }
      }).catch((error) => {
        FormUtils.submitShowError(config, error.response);
      });
    } else {
      instance.$http.request({
        method: 'POST',
        url: config.endpoint,
        headers: { 'Content-Type': undefined },
        withCredentials: true,
        transformRequest: (data) => {
          let dataToSend = jsonToFormData(config.formData);

          for (let i = 0; i < imageFields.length; i++) {
            if (typeof imageFields[i] === 'string') {
              dataToSend.append(imageFields[i], data[imageFields[i]]);
            } else {
              dataToSend.append(imageFields[i].key, imageFields[i].value);
            }
          }

          return dataToSend;
        },
        data: config.formData
      }).then(response => {
        if (response.data.success) {
          FormUtils.submitShowSuccess(config, response);
        } else {
          FormUtils.submitShowError(config, response);
        }
      }).catch(error => {
        console.log(error);
        FormUtils.submitShowError(config, error.response);
      });
    }
  }

  static submitShowSuccess (config, response) {
    Vue.swal({
      title: config.successTitle,
      html: config.successText,
      icon: 'success',
      confirmButtonText: 'OK',
      buttonsStyling: true
    }).then(() => {
      if (config.successAction) {
        config.successAction(response.data);
      }
    }).catch((errors) => {
      console.log(errors);
    });
  }

  static submitShowError (config, response) {
    let errorText = config.errorText;

    if (response && response.data && response.data.errors) {
      let keys = Object.keys(response.data.errors);
      errorText = [];

      for (let i = 0; i < keys.length; i++) {
        errorText.push(response.data.errors[keys[i]]);
      }

      errorText = errorText.join('<br>');
    }

    Vue.swal({
      title: config.errorTitle,
      html: errorText,
      icon: 'warning',
      confirmButtonText: 'OK',
      buttonsStyling: true
    }).then(() => {
      if (config.failAction) {
        config.failAction(response);
      }
    });
  }

  static submitInBackground (instance, config) {
    instance.$http.post(
      config.endpoint,
      config.params,
      {
        withCredentials: true
      }
    ).then(response => {
      config.successAction(response.data);
    }).catch(error => {
      console.log(error);

      if (config.failAction) {
        config.failAction(error.response);
      }

      if (!config.skipErrorDisplay) {
        FormUtils.submitShowError(config, error.response);
      }
    });
  }
}
