export default class UniversalUtils {
  static downloadFile (fileData, fileName) {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  }
}
