import Vue from 'vue';
import qs from 'qs';

export default class ListUtils {
  static loadItemsData (instance, config) {
    instance.loaded = false;
    instance.loadError = false;
    let requestMethod = 'post';
    let parameters = config.params || {};

    if (config.method) {
      requestMethod = config.method;
    }

    if (config.method === 'get') {
      parameters = {
        params: parameters,
        paramsSerializer: params => qs.stringify(params)
      };
    }

    instance.$http[requestMethod](config.endpoint, parameters, {
      withCredentials: true
    }).then(response => {
      instance[config.listField] = response.data.data;
      instance.loaded = true;

      if (config.pagination) {
        instance.paginationMetaData = response.data.meta || null;
      }
    }).catch(error => {
      console.log(error);
      instance.loadError = true;
    });
  }

  static removeItem (instance, config) {
    Vue.swal({
      title: config.confirmTitle,
      text: config.confirmText,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Tak, usuń',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        instance.$http.post(config.endpointBase + config.id, {}, {
          withCredentials: true
        }).then(response => {
          if (response.data.success) {
            Vue.swal({
              title: config.successTitle,
              html: config.successText,
              icon: 'success',
              confirmButtonText: 'OK',
              buttonsStyling: true
            }).then(() => {
              config.successAction();
            }).catch((errors) => {
              console.log(errors);
            });
          } else {
            Vue.swal({
              title: config.errorTitle,
              html: config.errorText,
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            config.failAction();
          }
        }).catch(error => {
          console.log(error);
          Vue.swal({
            title: config.errorTitle,
            html: config.errorText,
            icon: 'warning',
            confirmButtonText: 'OK',
            buttonsStyling: true
          });
          config.failAction();
        });
      } else {
        config.cancelAction();
      }
    });
  }
}
