<template>
  <div class="order-creator-service-samples-info">
    <div
      v-if="dataLoaded">
      <d-container
        v-for="(sample, index) of samples"
        :key="'sample-item-' + index"
        :class="{
          'mt-4': true,
          'mb-0': true,
          'mx-0': true,
          'pt-0': true,
          'pb-0': true,
          'px-0': true,
          'w-100': true,
          'sample-item': true,
          'bg-light': index%2 === 0
        }"
        style="border-radius: 0.625rem; max-width: unset;">
        <d-link
          class="dropdown-toggle"
          v-d-toggle="'sample-' + index + '-collapse'">
          <d-row
            class="pl-4 py-2 border-bottom mx-0 my-0"
            style="background: #F5F6F8; border-radius: 0.625rem 0.625rem 0 0;">
            <h6
              class="mb-0 d-flex align-items-center flex-wrap"
              style="width: 100%">
              <span class="pr-1 col-sm-12 col-md-4">Próba z dnia {{ prepareDateString(samples[index].created_at) }}</span>
              <span
                v-if="samples[index].name"
                class="pr-1 col-sm-12 col-md-4">
                Nazwa: {{ samples[index].name }}
              </span>
              <span
                v-if=" samples[index].status"
                class="col-sm-12 col-md-4">
                Status:
                <span
                  :class="{
                    'text-success': sample.status === 'APPROVED',
                    'text-warning': sample.status === 'WAITING',
                    'text-danger': sample.status === 'REJECTED'
                  }">
                  {{ statusOptions.find( item => item.value === samples[index].status).text }}
                </span>
              </span>
            </h6>
          </d-row>
        </d-link>
        <d-collapse
          visible
          :id="'sample-' + index + '-collapse'">
          <div class="d-flex pt-2 pb-3 px-2 align-items-start flex-wrap">
            <h6 class="pl-3 pt-2 d-block">Zdjęcia podglądów:</h6>
            <div
              v-if="sample.images.length"
              style="background: #fff;"
              class="w-100 pt-3 px-0 d-flex flex-wrap">
              <img
                v-for="(image, imgIndex) of sample.images"
                :key="'sample-item-' + index + '-image' + imgIndex"
                :src="image.file_url"
                height="150"
                width="150"
                :alt="image.file"
                @click="showImgFullScreen"
                style="object-fit: contain; cursor: pointer;"
                class="mb-3 mx-3"/>
            </div>
            <div v-else-if="!sample.images.length" class="w-100 text-center">
              Brak zdjęć dla tej próby...
            </div>
            <div v-else class="w-100 pl-3 text-center">
              Trwa wczytywanie zdjęć...
            </div>
          </div>
        </d-collapse>
      </d-container>
    </div>
    <div
      v-if="!dataLoaded"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>

    <d-modal
      size="lg"
      :class="{ 'is-hidden': !showImgFull, 'imageModal': true }"
      @close="handleImgClose">
      <d-modal-header>
        Zdjęcie próby
      </d-modal-header>
      <d-modal-body>
        <img
          :src="imageFullUrl"
          style="object-fit: contain; cursor: pointer; max-width: 100%"
          class="d-block mx-auto"/>
      </d-modal-body>
    </d-modal>

  </div>
</template>

<script>
import Vue from 'vue';
import SampleStatuses from '@/data/sample-statuses.js';

export default {
  name: 'order-creator-service-samples-info',
  props: [
    'samples'
  ],
  computed: {
    statusOptions () {
      return SampleStatuses;
    }
  },
  data () {
    return {
      dataLoaded: false,
      showImgFull: false,
      imageFullUrl: null
    };
  },
  mounted () {
    for (let i = 0; i < this.samples.length; i++) {
      if ('embroidery_over_55' in this.samples[i]) {
        this.samples[i].embroidery_over_55 = !!this.samples[i].embroidery_over_55;
      }
    }

    this.dataLoaded = true;
  },
  methods: {
    getSamples() {
      let samplesToSend = [];
      for (let i = 0; i < this.samples.length; i++) {
        samplesToSend.push({
          id: this.samples[i].id,
          name: this.samples[i].name,
          preparation_time: this.samples[i].preparation_time,
          embroidery_over_55: this.samples[i].embroidery_over_55 ? 1 : 0,
          status: this.samples[i].status
        });
      }

      return samplesToSend;
    },
    prepareDateString (date) {
      date = new Date(date);

      return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    showImgFullScreen (e) {
      setTimeout(() => {
        Vue.set(this, 'imageFullUrl', e.target.currentSrc);
        Vue.set(this, 'showImgFull', true);
      }, 0);
    },
    handleImgClose () {
      Vue.nextTick(() => {
        Vue.set(this, 'imageFullUrl', null);
        Vue.set(this, 'showImgFull', false);
      });
    },
    getStrandsString (strands) {
      if (!strands || !strands.length) {
        return '';
      }

      let strandsString = '';
      for (let i = 0; i < strands.length; i++) {
        strandsString += strands[i].strand_name + ';  ';
      }
      return strandsString;
    }
  }
};
</script>

<style lang="scss">
.modal.is-hidden {
  display: none!important;
  pointer-events: none!important;
}
.sample-item .dropdown-toggle {
  position: relative;
  &:after {
    position: absolute;
    left: 3px;
    top: 22px;
  }
}

@media screen  and (min-width: 1240px) {
  .imageModal .modal-dialog {
    max-width: 1100px;
  }
}

@media screen  and (min-width: 1350px) {
  .imageModal .modal-dialog {
    max-width: 1240px;
  }
}

@media (min-width: 576px) {
  .imageModal .modal-dialog {
      max-width: 90%;
  }
}

@media (max-width: 576px) {
  .imageModal .modal-dialog {
      max-width: 98%;

      .modal-header,
      .modal-body {
        padding: 0.75rem;
      }
  }
}

</style>
