<template>
  <div class="orders-view-wrapper">
    <orders v-if="isAdmin || isOffice" />
    <orders-operator v-if="isOperator" />
    <orders-controller v-if="isController" />
  </div>
</template>

<script>
import Orders from './Orders.vue';
import OrdersOperator from './OrdersOperator.vue';
import OrdersController from './OrdersController.vue';

export default {
  name: 'orders-wrapper',
  components: {
    'orders': Orders,
    'orders-operator': OrdersOperator,
    'orders-controller': OrdersController
  },
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    },
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    },
    isOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    isOperator () {
      return this.$store.getters['getUserType'] === 'OPERATOR';
    },
    isController() {
      return this.$store.getters['getUserType'] === 'CONTROLLER';
    }
  }
};
</script>
