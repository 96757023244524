<template>
  <div class="order-creator-service">
    <d-alert
      v-if="!isBlocked && !services.length"
      theme="warning"
      show
      class="mt-2">
      Dodaj zdobienia do produktu klikając przycisk "Dodaj zdobienie"
    </d-alert>

    <div
      v-if="dataLoaded">
      <d-card
        v-for="(service, index) of services"
        :key="'service-item-' + index"
        class="mt-2">
        <d-card-header class="h6 px-3 mb-0">
          <d-row>
            <d-col :md="userIsOffice ? 10 : 4">
              <span class="d-inline-block">Zdobienie #{{ index + 1 }}</span>
            </d-col>
            <d-col
              v-if="!userIsOffice"
              md="2 text-primary">
              <small>Cena za sztukę: <strong>{{ roundProperly(service.price) }} zł</strong></small>
            </d-col>
            <d-col
              v-if="!userIsOffice"
              md="2 text-primary">
              <small>Koszt setup: <strong>{{ roundProperly(service.price_setup) }} zł</strong></small>
            </d-col>
            <d-col
              v-if="!userIsOffice"
              md="2 text-primary">
              <small>Koszt całkowity: <strong>{{ roundProperly(getServiceTotalPrice(service, index)) }} zł</strong></small>
            </d-col>
            <d-col md="2">
              <d-button
                v-if="isProductSetup && !isQAOnly"
                theme="danger"
                outline
                class="ml-auto d-block"
                :disabled="isBlocked"
                @click.prevent="removeService(index)">
                <i class="material-icons">delete</i>
                Usuń
              </d-button>
            </d-col>
          </d-row>
        </d-card-header>
        <d-card-body class="px-2 px-lg-3 py-3 py-lg-4">
          <d-alert
            v-if="!!service.deleted_in_hpl"
            show
            theme="danger"
            class="mb-4">
            To zdobienie zostało usunięte w zamówieniu HPL.
          </d-alert>
          <div class="product-info">
            <d-row class="mt-2">
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-group-' + productIndex + '-' + index">
                    Zdobienie:
                  </label>
                  <validation-provider
                    :name="'service-group-' + productIndex + '-' + index"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="allServiceGroups"
                        v-model="service.service_group_id"
                        :reduce="group => group.id"
                        placeholder="Wybierz zdobienie"
                        @input="resetSelectedVariants(index)"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-variant-' + productIndex + '-' + index">
                    Wybierz wariant zdobienia:
                  </label>

                  <validation-provider
                    :name="'service-variant-' + productIndex + '-' + index"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="getAllServicesFromGroup(service.service_group_id)"
                        @input="getSelectedServicePrices(service.service_id, index)"
                        @change="getSelectedServicePrices(service.service_id, index)"
                        v-model="service.service_id"
                        :reduce="(service) => service.id"
                        placeholder="Wybierz wariant zdobienia"
                        label="name">
                        <template slot="no-options">
                          Brak zdobień w wybranej grupie
                        </template>
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>
            <d-row class="mt-1">
              <d-col sm="4">
                <div class="form-group">
                  <label :for="'service-embroidery-type-' + productIndex + '-' + index">
                    Rodzaj haftu:
                  </label>

                  <validation-provider
                    :name="'service-embroidery-type-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="allEmbroideryTypes"
                        v-model="service.embroidery_type_id"
                        :reduce="(type) => type.id"
                        placeholder="Wybierz rodzaj haftu"
                        label="name">
                      </v-select>
                    </div>
                  </validation-provider>
                </div>
              </d-col>

              <d-col sm="4">
                <div class="form-group">
                  <label :for="'service-embroidery-position-' + productIndex + '-' + index">
                    Pozycja dekoracji:
                  </label>

                  <validation-provider
                    :name="'service-embroidery-position-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="allEmbroideryPositions"
                        v-model="service.embroideryPositionNameVal"
                        :reduce="(position) => position.name"
                        placeholder="Wybierz pozycję"
                        :searchable="true"
                        :multiple="false"
                        label="name">
                        <template slot="no-options">
                          Brak takiej pozycji&hellip;
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </div>
                  </validation-provider>
                </div>
              </d-col>

              <d-col sm="4">
                <div class="form-group">
                  <label :for="'service-embroidery-color-' + productIndex + '-' + index">
                    Kolor haftu:
                  </label>

                  <validation-provider
                    :name="'service-embroidery-color-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input
                      v-model="service.embroidery_color"
                      :disabled="isBlocked || isQAOnly || !isProductSetup"
                      :state="errors.length ? 'invalid' : null"/>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>

            <d-row class="mt-1">
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-embroidery-height-' + productIndex + '-' + index">
                    Wysokość haftu:
                  </label>

                  <validation-provider
                    :rules="service.embroidery_width ? '' : 'required'"
                    :name="'service-embroidery-height-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input
                      v-model="service.embroidery_height"
                      :disabled="isBlocked || isQAOnly || !isProductSetup"
                      :state="errors.length ? 'invalid' : null"
                      type="number"/>
                    <div class="invalid-feedback">
                      Wymagana szerokość lub wysokość
                    </div>
                  </validation-provider>
                </div>
              </d-col>

              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-embroidery-width-' + productIndex + '-' + index">
                    Szerokość haftu:
                  </label>

                  <validation-provider
                    :rules="service.embroidery_height ? '' : 'required'"
                    :name="'service-embroidery-width-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input
                      v-model="service.embroidery_width"
                      :disabled="isBlocked || isQAOnly || !isProductSetup"
                      :state="errors.length ? 'invalid' : null"
                      type="number"/>
                    <div class="invalid-feedback">
                      Wymagana szerokość lub wysokość
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>

            <d-row class="mt-1">
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-quantity-' + productIndex + '-' + index">
                    Liczba sztuk:
                  </label>

                  <validation-provider
                    :rules="'required|only-natural-numbers|min_value:1|max_value:' + productQuantity"
                    :name="'service-quantity-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input-group
                      append="szt."
                      :class="{'is-invalid': errors.length}">
                      <d-input
                        type="number"
                        step="1"
                        min="0"
                        :max="productQuantity"
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        @change="getSelectedServicePrices(service.service_id, index)"
                        class="text-right"
                        v-model="service.quantity"
                        :state="errors.length ? 'invalid' : null"/>
                    </d-input-group>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>

              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-dst-' + productIndex + '-' + index">
                    DST:
                  </label>
                  <validation-provider
                    :name="'service-dst-' + productIndex + '-' + index"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="dstOptions"
                        v-model="service.dst"
                        :reduce="dst => dst.value"
                        placeholder="Wybierz DST"
                        label="text">
                      </v-select>
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>
          </div>
          <hr>
          <div class="pb-4">
            <d-row class="mt-2">
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-number-dst-' + productIndex + '-' + index">
                    Numer DST:
                  </label>

                  <validation-provider
                    :name="'service-number-dst-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input
                      :disabled="isBlocked || isQAOnly || dstAccepted"
                      :state="errors.length ? 'invalid' : null"
                      v-model="service.dst_number" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col sm="6">
                <div class="form-group">
                  <label :for="'service-contractor-dst-' + productIndex + '-' + index">
                    Wykonawca DST:
                  </label>

                  <validation-provider
                    :name="'service-contractor-dst-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || dstAccepted"
                        :options="allContractors"
                        v-model="service.contractor_id"
                        :reduce="(contractor) => contractor.id"
                        placeholder="Wybierz wykonawcę DST"
                        :searchable="true"
                        label="name">
                        <template slot="no-options">
                          Brak pasujących rezultatów
                        </template>
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>
            <d-row v-if="service.dst === 'CUSTOMER' || service.dst === 'ORDER'">
              <d-col
                v-if="service.dst === 'CUSTOMER'"
                sm="4">
                <div class="form-group">
                  <label :for="'service-client-disc-' + productIndex + '-' + index">
                    Dysk klienta:
                  </label>
                  <d-checkbox
                    :disabled="isBlocked || isQAOnly || dstAccepted"
                    toggle
                    toggle-small
                    class="mr-0 d-block"
                    inline
                    v-model="service.dst_disc"/>
                </div>
              </d-col>
              <d-col
                v-if="service.dst === 'ORDER'"
                sm="4">
                <div class="form-group">
                  <label :for="'dst-receive-date' + productIndex + '-' + index">
                    Data otrzymania DST:
                  </label>
                  <validation-provider
                    :name="'dst-receive-date' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-date-range': true,
                        'vs-wrap-text': true,
                        'is-invalid': errors.length,
                        'is-disabled': isBlocked || isQAOnly || dstAccepted
                      }">
                      <date-range-picker
                        ref="picker"
                        :locale-data="{
                          'firstDay': 1,
                          'format': 'DD-MM-YYYY',
                          'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                          'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                          'applyLabel': 'OK',
                          'cancelLabel': 'Anuluj'
                        }"
                        :class="{ 'w-100': true, 'is-disabled': isQAOnly || dstAccepted }"
                        :disabled="isBlocked || isQAOnly || dstAccepted"
                        :singleDatePicker="true"
                        :timePicker="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        v-model="service.dstReceiveDate"
                        :ranges="false"
                        :linkedCalendars="true"
                        :date-format="dateFormat"
                        :auto-apply="true"
                        @update="updateDstReceiveDates">
                        <template v-slot:input="picker">
                          {{ prepareDateString(picker.startDate) }}
                        </template>
                      </date-range-picker>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col
                v-if="service.dst === 'ORDER'"
                sm="4">
                <div class="form-group">
                  <label :for="'service-client-disc-' + productIndex + '-' + index">
                    Rozliczono z dostawcą:
                  </label>
                  <d-checkbox
                    :disabled="isBlocked || isQAOnly"
                    toggle
                    toggle-small
                    class="mr-0 d-block"
                    inline
                    v-model="service.dst_settled"/>
                </div>
              </d-col>
            </d-row>
            <d-row class="mt-1">
              <d-col sm="4">
                <div class="form-group">
                  <label :for="'service-stitch-number-' + productIndex + '-' + index">
                    Liczba ściegów:
                  </label>

                  <validation-provider
                    rules="only-natural-numbers"
                    :name="'service-stitch-number-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <d-input
                      :disabled="isBlocked || isQAOnly || dstAccepted"
                      class="text-right"
                      type="number"
                      :min="1"
                      :step="1"
                      :state="errors.length ? 'invalid' : null"
                      v-model="service.stitch_number" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col sm="8">
                <div class="form-group">
                  <label :for="'service-real-' + productIndex + '-' + index">
                    Prawdziwy wariant zdobienia:
                  </label>

                  <validation-provider
                    :name="'service-real-' + productIndex + '-' + index"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || dstAccepted"
                        :options="getAllServicesFromGroup(service.service_group_id)"
                        v-model="service.real_service_id"
                        :reduce="(service) => service.id"
                        placeholder="Wybierz prawdziwy wariant zdobienia"
                        :searchable="true"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>
            <d-row class="mt-1">
              <d-col cols="12">
                <strand-select
                  :ref="`${productIndex}-${index}-strand-select`"
                  :all-strands="allStrands"
                  :current-strands="service.strands"
                  :is-disabled="isBlocked || isQAOnly || dstAccepted"
                  :is-read-only="dstAccepted"
                  :index="`${productIndex}-${index}`" />
              </d-col>
            </d-row>
            <small
              v-if="status === null"
              class="text-secondary">
              Uzupełnienie powyższej grupy pól będzie możliwe po zapisaniu zamówienia.
            </small>

            <hr>

            <d-row>
              <d-col sm="12">
                <div class="form-group">
                  <label for="description">
                    Uwagi:
                  </label>
                  <d-textarea
                    name="description"
                    v-model="service.description"
                    :disabled="isBlocked"
                    :value.prop="service.description"
                    :rows="2"/>
                </div>
              </d-col>
            </d-row>
          </div>

          <files-list
            v-if="service.files && service.files.length"
            :files="service.files"
            class="mb-4" />

          <d-tabs card >
            <!-- Próby zdobienia -->
            <d-tab
              title="Próby zdobienia"
              :active="isInPreparation"
              class="px-2">
              <d-row class="mb-0 pb-2 border-bottom d-flex justify-content-between align-items-center">
                <d-col
                  sm="12"
                  md="9"
                  lg="9">
                  <h5 class="my-0">Próby zdobienia</h5>
                </d-col>
                <d-col
                  sm="12"
                  md="3"
                  lg="3">
                  <d-button
                    :disabled="isBlocked || !isSamplesOnly || getServiceSamplesApproved(service)"
                    theme="success"
                    class="d-block ml-auto my-auto"
                    @click.prevent="$refs['order-creator-service-samples'][index].addSample()">
                    <i class="material-icons">add</i>
                    Dodaj próbę
                  </d-button>
                </d-col>
              </d-row>

              <template v-if="!isProductSetup">
                <order-creator-service-samples
                  ref="order-creator-service-samples"
                  :samples="service.samples"
                  :productIndex="productIndex"
                  :serviceIndex="index"
                  :allStrands="allStrands"
                  :isReadOnly="isBlocked || !isSamplesOnly"/>
              </template>
              <template v-else>
                <span class="d-block pt-4">
                  Dodawanie prób będzie możliwie po zaakceptowaniu DST.
              </span>
              </template>
            </d-tab>
            <!-- Maszyny realizujące zdobienie -->
            <d-tab
              title="Maszyny realizujące zdobienie"
              :active="!isInPreparation"
              class="px-2">
              <d-row class="mb-0 pb-2 border-bottom d-flex justify-content-between align-items-center">
                <d-col
                  sm="12"
                  md="8"
                  lg="8">
                  <h5 class="my-0">Maszyny realizujące zdobienie</h5>
                </d-col>
                <d-col
                  col
                  sm="12"
                  md="4"
                  lg="4">
                  <d-button
                    :disabled="isBlocked || toBeDelivered || (isQAOnly) || (!service.samples.find(sample => sample.status === 'APPROVED')) || isAllQuantityAssigned(service)"
                    class="d-block ml-auto my-auto"
                    theme="success"
                    @click.prevent="$refs['order-creator-service-machine'][index].addMachine(service.quantity)">
                    <i class="material-icons">add</i>
                    Dodaj maszynę
                  </d-button>
                </d-col>
              </d-row>
              <div
                v-if="!(toBeDelivered) && !service.difference_accepted && getMachinesNotAssignedCount(index) && (status === 'WAITING_ADDITIONAL_PRODUCTS' || status === 'IN_REALIZATION')">
                  <d-alert
                    theme="warning"
                    show
                    class="my-0">
                    W wyniku kontroli zostało domówionych <strong>{{ rejectedCount }}</strong> produktów z czego <strong>{{ getMachinesNotAssignedCount(index) }}</strong> nie jest przypisanych do maszyn. Przypisz produkty aby kontynuować produkcję.
                  </d-alert>
                  <d-button
                    class="d-block ml-auto my-3"
                    theme="success"
                    :disabled="isBlocked"
                    @click.prevent="acceptDifference(services[index].id, index)">
                    Akceptuj różnicę
                  </d-button>
              </div>

              <template v-if="service.samples.find(sample => sample.status === 'APPROVED')">
                <order-creator-service-machine
                  :ref="'order-creator-service-machine'"
                  :machines="service.machines"
                  :allMachines="allMachines"
                  :allWorkers="allWorkers"
                  :serviceName="service.service_name"
                  :serviceID="service.service_id"
                  :orderServiceID="service.id"
                  :orderName="orderName"
                  :productCode="productCode"
                  :serviceQuantity="service.quantity"
                  :isReadOnly="isBlocked || isQAOnly || (status === 'WAITING_ADDITIONAL_PRODUCTS' && toBeDelivered)"
                  :isReorder="rejectedCount > 0"
                  :differenceAccpeted="service.difference_accepted"
                  :rejectedCount="rejectedCount"/>
              </template>
              <template v-else>
                <span class="d-block pt-4">
                  Dodawanie maszyny będzie możliwie po zaakceptowaniu próby.
                </span>
              </template>
            </d-tab>
          </d-tabs>
        </d-card-body>
      </d-card>
    </div>
    <div
      v-if="!dataLoaded"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CustomDecimals from '@/utils/CustomDecimals.js';
import OrderCreatorServiceSamples from './OrderCreatorServiceSamples.vue';
import OrderCreatorServiceMachine from './OrderCreatorServiceMachine.vue';
import StrandSelect from '@/components/forms/order/StrandSelect.vue';
import DstTypes from '@/data/dst-types.js';
import FilesList from '@/components/forms/order/FilesList.vue';

export default {
  name: 'order-creator-service',
  props: [
    'services',
    'productID',
    'productIndex',
    'productQuantity',
    'orderName',
    'productCode',
    'allContractors',
    'allEmbroideryPositions',
    'allEmbroideryTypes',
    'allServiceGroups',
    'allServices',
    'allStrands',
    'allMachines',
    'allWorkers',
    'isBlocked',
    'isQAOnly',
    'isSamplesOnly',
    'isInPreparation',
    'isProductSetup',
    'toBeDelivered',
    'status',
    'rejectedCount',
    'performPriceRecalculate'
  ],
  components: {
    'date-range-picker': DateRangePicker,
    'order-creator-service-samples': OrderCreatorServiceSamples,
    'order-creator-service-machine': OrderCreatorServiceMachine,
    'strand-select': StrandSelect,
    'files-list': FilesList
  },
  computed: {
    allDataLoaded () {
      return this.dataLoaded && this.loadedPrices && this.isPricesSet;
    },
    userIsOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    dstOptions () {
      return DstTypes;
    },
    clientDiskOptions () {
      return [
        {
          text: 'Tak',
          value: 1
        },
        {
          text: 'Nie',
          value: 0
        }
      ];
    },
    dstAccepted () {
      if (this.status === 'NEW' || this.status === 'WAITING_PRODUCTS' || this.status === 'WAITING_DST') {
        return false;
      }

      return true;
    },
    availableStrandColors () {
      if (this.allStrands) {
        return this.allStrands.map(strand => strand.name);
      }

      return [];
    }
  },
  data () {
    return {
      acceptDifferenceInProgress: false,
      allPrices: [],
      dataLoaded: false,
      isPricesSet: false,
      loadedPrices: false,
      loadError: false,
      reorderCount: 0,
      reorderNotFull: false
    };
  },
  mounted () {
    if (this.isProductSetup && !this.isQAOnly) {
      this.loadAdditionalData();
    }
    for (let i = 0; i < this.services.length; i++) {
      this.services[i].dst_disc = !!this.services[i].dst_disc;
      this.services[i].dst_settled = !!this.services[i].dst_settled;
      this.services[i].reorderAssignedCount = 0;

      if (this.services[i].embroidery_position_id && this.services[i].embroidery_position_name) {
        this.services[i].embroideryPositionNameVal = this.services[i].embroidery_position_name;
      } else if (this.services[i].embroidery_position) {
        this.services[i].embroideryPositionNameVal = this.services[i].embroidery_position;
      }
    }

    this.dataLoaded = true;
  },
  methods: {
    addService () {
      this.services.push({
        id: null,
        contractor_id: null,
        description: '',
        dst: null,
        dst_disc: false,
        dst_number: '',
        dst_settled: false,
        dstReceiveDate: {
          startDate: null
        },
        embroidery_color: '',
        embroidery_height: 0,
        embroidery_position: null,
        embroidery_position_id: 0,
        embroidery_position_name: null,
        embroidery_type_id: 0,
        embroidery_width: 0,
        embroideryPositionNameVal: '',
        machines: [],
        price: 0,
        price_multiple_from: 0,
        price_setup: 0,
        quantity: this.productQuantity,
        real_service_id: 0,
        samples: [],
        service_group_id: null,
        service_id: null,
        stitch_number: 0,
        strands: [],
        totalPrice: 0
      });

      Vue.nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      });
    },
    removeService (serviceIndex) {
      Vue.swal({
        title: 'Czy na pewno chcesz usunąć to zdobienie?',
        text: 'Aby operacja została zapisana należy zapisać zamówienie',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.services.splice(serviceIndex, 1);
        }
      });
    },
    removeServicesWithQuantityZero () {
      let servicesCopy = JSON.parse(JSON.stringify(this.services));
      for (let i = 0; i < servicesCopy.length; i++) {
        if (!servicesCopy[i].quantity) {
          this.services.splice(i, 1);
        }
      }
    },
    getServices () {
      let servicesToSend = [];
      for (let i = 0; i < this.services.length; i++) {
        let currentService = this.services[i];

        let serviceToSave = {
          id: currentService.id,
          contractor_id: currentService.contractor_id,
          description: currentService.description,
          dst: currentService.dst,
          dst_disc: currentService.dst_disc ? 1 : 0,
          dst_number: currentService.dst_number,
          dst_receive_date: currentService.dst_receive_date,
          dst_settled: currentService.dst_settled ? 1 : 0,
          embroidery_color: currentService.embroidery_color,
          embroidery_height: Number(currentService.embroidery_height),
          embroidery_position: currentService.null,
          embroidery_position_id: 0,
          embroidery_position_name: null,
          embroidery_type_id: currentService.embroidery_type_id,
          embroidery_width: Number(currentService.embroidery_width),
          machines: [],
          quantity: parseFloat(currentService.quantity),
          samples: [],
          service_group_id: currentService.service_group_id,
          service_id: currentService.service_id,
          stitch_number: currentService.stitch_number,
          strands: currentService.strands
        };

        if (currentService.embroideryPositionNameVal) {
          let existingPosition = this.allEmbroideryPositions.find(item => item.name === currentService.embroideryPositionNameVal);

          if (existingPosition) {
            serviceToSave.embroidery_position_id = existingPosition.id;
            serviceToSave.embroidery_position_name = existingPosition.name;
          } else {
            serviceToSave.embroidery_position = currentService.embroideryPositionNameVal;
          }
        }

        if (this.$refs[`${this.productIndex}-${i}-strand-select`]) {
          serviceToSave.strands = this.$refs[`${this.productIndex}-${i}-strand-select`][0].getStrands();
        }

        if (this.isProductSetup) {
          serviceToSave.price_multiple_from = Number(this.roundProperly(currentService.price_multiple_from));
        }

        serviceToSave.price = Number(this.roundProperly(currentService.price));
        serviceToSave.price_setup = Number(this.roundProperly(currentService.price_setup));

        if (('real_service_id' in currentService) && (currentService.real_service_id !== 0)) {
          serviceToSave.real_service_id = currentService.real_service_id;
        }

        if (this.$refs['order-creator-service-samples'] && this.$refs['order-creator-service-samples'][i]) {
          serviceToSave.samples = this.$refs['order-creator-service-samples'][i].getSamples();
        }
        if (this.$refs['order-creator-service-machine'] && this.$refs['order-creator-service-machine'][i]) {
          serviceToSave.machines = this.$refs['order-creator-service-machine'][i].getMachines();
        }

        servicesToSend.push(serviceToSave);
      }

      return servicesToSend;
    },
    getAllServicesFromGroup (serviceGroupID) {
      let servicesFromGroup = this.allServices.filter(service => service.group_id === serviceGroupID);

      return servicesFromGroup;
    },
    resetSelectedVariants (index) {
      this.services[index].service_id = null;
    },
    acceptDifference (serviceID, serviceIndex) {
      this.acceptDifferenceInProgress = true;

      this.$http.request({
        method: 'POST',
        url: '/api/orders/services/set-difference-accepted/' + serviceID,
        headers: { 'Content-Type': undefined },
      }).then(() => {
        this.acceptDifferenceInProgress = false;
        Vue.set(this.services[serviceIndex], 'difference_accepted', true);
        this.$bus.$emit('order-save');
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas zapisu',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.acceptDifferenceInProgress = false;
      });
    },
    getMachinesNotAssignedCount (index) {
      if (!this.$refs['order-creator-service-machine'] || !this.$refs['order-creator-service-machine'][index]) {
        return 0;
      }

      return this.$refs['order-creator-service-machine'][index].getNotAssignedCount();
    },
    getSelectedServicePrices (serviceID, serviceIndex) {
      if (serviceID) {
        this.services[serviceIndex].price_multiple_from = this.allServices.find(item => item.id === serviceID).price_multiple_from;

        let currentService = this.services[serviceIndex];
        let quantity = Number(currentService.quantity);
        let servicePrices = this.allPrices.filter(price => price.service_id === serviceID && !price.is_setup);
        let serviceSetupPrices = this.allPrices.filter(price => price.service_id === serviceID && price.is_setup);

        servicePrices.sort((priceA, priceB) => priceB.value_from - priceA.value_from);
        let priceIndexToUse = -1;
        for (let i = 0; i < servicePrices.length; i++) {
          if (servicePrices[i].value_from <= quantity) {
            priceIndexToUse = i;
            break;
          }
        }
        if (priceIndexToUse > -1) {
          Vue.set(this.services[serviceIndex], 'price', servicePrices[priceIndexToUse].price);
        }

        serviceSetupPrices.sort((priceA, priceB) => priceA.value_from - priceB.value_from);
        priceIndexToUse = -1;
        for (let i = 0; i < serviceSetupPrices.length; i++) {
          if (serviceSetupPrices[i].value_from >= quantity) {
            priceIndexToUse = i;
            break;
          }
        }
        if (priceIndexToUse > -1) {
          Vue.set(this.services[serviceIndex], 'price_setup', serviceSetupPrices[priceIndexToUse].price);
        }
      } else {
        Vue.set(this.services[serviceIndex], 'price_setup', 0);
        Vue.set(this.services[serviceIndex], 'price', 0);
      }
    },
    getServiceTotalPrice (service, serviceIndex) {
      let quantity = Number(service.quantity);
      let totalPrice = 0;

      if (quantity >= Number(service.price_multiple_from)) {
        totalPrice = ((Number(service.price) * quantity) + Number(service.price_setup));
      } else {
        totalPrice = (Number(service.price) + Number(service.price_setup));
      }
      Vue.set(this.services[serviceIndex], 'totalPrice', totalPrice);

      return totalPrice;
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/prices/items',
        method: 'get',
        outputKey: 'allPrices',
        loadedKey: 'loadedPrices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        },
        successAction: () => {
          if (this.performPriceRecalculate) {
            this.recalculatePrices();
          } else {
            this.isPricesSet = true;
          }
        }
      });
    },
    roundProperly (value, decimals = 2) {
      return CustomDecimals.roundProperly(value, decimals);
    },
    getServiceSamplesApproved (service) {
      return service.samples.filter(sample => sample.status === 'APPROVED').length > 0;
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    dateFormat (classes) {
      if (!classes.disabled) {
        classes.disabled = false;
      }
      return classes
    },
    updateDstReceiveDates () {
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].dstReceiveDate && this.services[i].dstReceiveDate.startDate) {
          this.services[i].dst_receive_date = this.prepareDateString(this.services[i].dstReceiveDate.startDate);
        } else {
          this.services[i].dst_receive_date = null;
        }
      }
    },
    isAllQuantityAssigned (service) {
      let rejected = this.rejectedCount ? this.rejectedCount : 0;
      let quantityAssigned = 0;
      quantityAssigned += service.machines.reduce((sum, item) => sum + Number(item.quantity), 0);

      return quantityAssigned === (service.quantity + rejected);
    },
    recalculatePrices () {
      for (let i = 0; i < this.services.length; i++) {
        this.getSelectedServicePrices(this.services[i].service_id, i);
      }

      if (this.performPriceRecalculate) {
        this.isPricesSet = true;
      }
    }
  }
};
</script>

<style lang="scss">
.order-creator-service {
  .is-disabled {
    background: #eee;
    cursor: not-allowed;
  }

  .form-control.is-date-range .vue-daterange-picker.is-disabled .reportrange-text {
    background: #f5f6f7!important;
    pointer-events: none;
  }
}
</style>
