<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">Lista zamówień</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          :disabled="exportInProgress"
          theme="primary"
          @click.prevent="exportFinished"
          class="mr-2">
          <i class="material-icons">cloud_download</i>
          Eksportuj zakończone
        </d-button>
        <d-button
          theme="success"
          @click.prevent="$router.push('/zamowienia/nowy')">
          <i class="material-icons">add</i>
          Dodaj zamówienie
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="allDataLoaded"
      style="width: 100%"
      class="d-flex ml-0 mb-3 page-filters">
      <validation-observer
        ref="orders-filters-form"
        tag="form"
        style="width: 100%"
        class="d-flex mb-2">
        <d-input
          placeholder="Nazwa zamówienia, Klient, nazwa/kod zdobienia"
          v-model="filters.search"
          class="mr-1 mb-2"/>
        <v-select
          style="min-width: 25%;"
          class="mr-1 mb-2 status-select"
          :options="orderStatusOptions"
          v-model="filters.orderStatus"
          :reduce="status => status.status_code"
          placeholder="Status zamówienia"
          :searchable="false"
          label="label">
        </v-select>

        <v-select
          style="min-width: 25%;"
          class="mr-0 mb-2 status-select"
          :options="allContractors"
          v-model="filters.contractorID"
          :reduce="item => item.id"
          placeholder="Wykonawca"
          :searchable="false"
          label="name">
        </v-select>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-auto ml-md-3 mr-1 mb-2"
          @click.prevent="validateFiltersForm">
          Szukaj
        </d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">
          Resetuj filtry
        </d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Zamówienie</th>
            <th scope="col" class="border-0 align-middle text-center">Data otrzymania produktów</th>
            <th scope="col" class="border-0 align-middle text-center">Szacowany deadline</th>
            <th scope="col" class="border-0 align-middle text-center">Zdobienia ukończone</th>
            <th scope="col" class="border-0 align-middle text-center">Status</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="allDataLoaded">
          <tr
            v-for="(order, index) of orders" :key="'order-' + index"
            :class="{'has-unread-logs': order.has_unread_logs === 1}">
            <td class="align-middle py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Zamówienie</label>
              <d-badge
                v-if="order.has_unread_logs === 1"
                theme="danger"
                class="mr-1"
                :title="'Zamówienie posiada nieprzeczytane powiadomienia'">
                !
              </d-badge>
              {{ order.order_name }}<br>
              <small>Klient: {{ order.customer_name }}</small>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Data otrzymania produktów</label>
              {{ order.products_planned_date }}
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Szacowany deadline</label>
              <template v-if="checkIfOverDeadline(order.deadline, order.status)">
                <span class="text-danger over-deadline">
                  {{ order.deadline }}
                  <i
                    class="material-icons alert-icon">
                    report_problem
                  </i>
                </span>
              </template>
              <template v-else>
                {{ order.deadline }}
              </template>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Zdobienia ukończone</label>
              <span :class="{'text-success': order.executed_services_count >= order.services_count}">
                {{ order.executed_services_count }} / {{ order.services_count }}
              </span>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Status</label>
              <d-badge
                :theme="orderStatuses[order.status] ? orderStatuses[order.status].style : 'secondary'"
                :outline="orderStatuses[order.status] ? orderStatuses[order.status].outline : false"
                class="cursor-pointer"
                :disabled="!order.id || !order.status"
                @click.native.prevent.stop="showStatusSelectPopup(order.id, order.status)">
                {{ orderStatuses[order.status] ? orderStatuses[order.status].label : 'Nieznany status' }}
              </d-badge>
              <br>
              <small>
                Ostatnia zmiana:<br>
                <strong>{{ order.status_at }}</strong>
              </small>
            </td>

            <td class="text-center align-middle pt-0">
              <d-button
                theme="primary"
                @click.native="$router.push('/zamowienia/edycja/' + order.id)"
                class="mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removeOrder(order.id)"
                title="Usuń"
                class="mx-2 mt-2">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="orders.length === 0">
            <td colspan="6" class="text-center">
              Brak zamówień do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!allDataLoaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert v-if="loadError" show theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
    <order-status-change-popup />
  </d-container>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import FormUtils from './../utils/FormUtils.js';
import HasFilters from './../mixins/HasFilters.vue';
import HasPagination from './../mixins/HasPagination.vue';
import ListUtils from './../utils/ListUtils.js';
import Pagination from './../components/common/Pagination.vue';
import OrderStatuses from './../data/order-statuses.js';
import OrderStatusChangePopup from '@/components/popups/OrderStatusChangePopup.vue';

export default {
  name: 'orders',
  mixins: [HasFilters, HasPagination],
  components: {
    'pagination': Pagination,
    'order-status-change-popup': OrderStatusChangePopup
  },
  computed: {
    orderStatuses () {
      return OrderStatuses;
    },
    orderStatusOptions () {
      let orderStatusesArr = [];
      let keys = Object.keys(OrderStatuses)
      for (let i = 0; i < keys.length; i++) {
        let orderStatus = OrderStatuses[keys[i]];
        orderStatus['status_code'] = keys[i];
        orderStatusesArr.push(orderStatus);
      }
      return orderStatusesArr;
    },
    allDataLoaded () {
      return this.loaded && this.loadedContractors;
    }
  },
  data() {
    return {
      allContractors: [],
      allCustomers: [],
      customersEmptyResults: false,
      customersLoaded: false,
      exportInProgress: false,
      filters: {
        contractorID: null,
        customerID: null,
        orderStatus: null,
        search: ''
      },
      loaded: false,
      loadedContractors: false,
      loadError: false,
      orders: [],
      removeInProgress: false
    };
  },
  mounted() {
    this.$bus.$on('order-status-change-popup-update', this.debouncedLoadFilteredData);
    this.loadFilteredData();
    this.loadAdditionalData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData() {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      if (this.filters.orderStatus) {
        where.status = this.filters.orderStatus;
      }

      if (this.filters.customerID) {
        where.customer_id = this.filters.customerID;
      }

      if (this.filters.contractorID) {
        where['service.contractor_id'] = this.filters.contractorID;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/orders/items?page=' + this.currentPage,
        listField: 'orders',
        pagination: true,
        params: {
          where
        },
      });
    },
    clearFilters() {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        contractorID: null,
        customerID: null,
        orderStatus: null,
        search: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
      this.$bus.$emit('pagination-reset', false);
    },
    validateFiltersForm() {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(
        this.$refs['orders-filters-form'],
        this.debouncedLoadFilteredData
      );
    },
    removeOrder(orderID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/orders/delete/',
        id: orderID,
        successTitle: 'Usunięto zmówienie',
        successText: 'Wybrane zamówienie zostało usunięte',
        confirmTitle: 'Czy na pewno chcesz usunąć to zamówienie?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie zamówienia nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        },
      });
    },
    checkIfOverDeadline (deadline, status) {
      if (status !== 'ENDED' && new Date(deadline) <= new Date()) {
        return true;
      }
      return false;
    },
    exportFinished () {
      this.exportInProgress = true;

      Vue.swal({
        title: 'Czy na pewno chcesz wyeksportować plik z zakończonymi zamówieniami?',
        text: 'Pobrany plik będzie miał format .xlsx',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, pobierz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.get('/api/orders/export-ended', {
            responseType: 'blob'
          }).then(response => {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'zakonczoneZamowienia.xlsx');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              Vue.swal({
                title: 'Cennik pobrany',
                html: 'Plik zakonczoneZamowienia.xlsx zawierający zakończone zamówienia został pobrany.',
                icon: 'success',
                confirmButtonText: 'OK',
                buttonsStyling: true
              }).then(() => {
                this.exportInProgress = false;
              }).catch((errors) => {
                console.log(errors);
              });
            } else {
              Vue.swal({
                title: 'Wystąpił błąd podczas eksportu',
                html: 'Spróbuj ponownie',
                icon: 'warning',
                confirmButtonText: 'OK',
                buttonsStyling: true
              });
              this.exportInProgress = false;
            }
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas eksportu',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.exportInProgress = false;
          });
        } else {
          this.exportInProgress = false;
        }
      });
    },
    showStatusSelectPopup (orderID, currentStatus) {
      this.$bus.$emit('order-status-change-popup-show', orderID, currentStatus);
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/contractors/items',
        method: 'get',
        outputKey: 'allContractors',
        loadedKey: 'loadedContractors',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('order-status-change-popup-update', this.debouncedLoadFilteredData);
  }
};
</script>

<style lang="scss">
.over-deadline i.alert-icon {
  top: 2px;
}
.cursor-pointer {
  cursor: pointer;
}

tr.has-unread-logs td {
  background: rgba(255,0,0,.125);
}
</style>
