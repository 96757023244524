<template>
  <div class="order-creator">
    <d-alert
      v-if="!isBlocked && !products.length"
      theme="warning"
      show>
      Dodaj produkty do zamówienia klikając przycisk "Dodaj produkt"
    </d-alert>

    <div
      v-if="allDataLoaded">
      <d-row v-if="products.length > 0 && !userIsOffice">
        <d-col
          sm="12"
          md="6"
          lg="6"
          class="text-primary pt-3 mt-1">
          Całkowity koszt zamówienia: <strong>{{roundProperly(getTotalOrderPrice())}} zł</strong>
        </d-col>
        <d-col
          v-if="!isProductSetup || isQAOnly"
          sm="12"
          md="6"
          lg="6"
          class="text-right mt-1">
          <d-button
            theme="primary"
            class="ml-auto mt-2"
            :disabled="isBlocked || !hasProductsWithServicesZeroQuantity"
            @click.prevent="removeServicesWithZeroQuantity">
            Usuń zerowe zdobienia
          </d-button>
        </d-col>
      </d-row>
      <d-row v-if="performPriceRecalculate" class="mt-2">
        <d-col col sm="12">
          <d-alert
            theme="warning"
            show>
            Ceny zostały ponownie przeliczone ponieważ zamówienie pochodzi z HPL.
          </d-alert>
        </d-col>
      </d-row>
      <d-card
        v-for="(product, index) of products"
        :key="'product-item-' + index"
        class="mt-4">
        <d-card-body class="px-2 px-lg-3 py-2 py-lg-2">
          <d-alert
            v-if="!!product.deleted_in_hpl"
            show
            theme="danger"
            class="mt-3 mb-4">
            Ten produkt został usunięty w zamówieniu HPL.
          </d-alert>
          <div>
            <d-row
              class="mt-2 d-flex">
              <d-col md="4">
                <div class="form-group">
                  <label :for="'product-code-' + index">
                    Kod produktu:
                  </label>

                  <validation-provider
                    rules="required"
                    :name="'product-code-' + index"
                    v-slot="{ errors }">
                    <d-input
                      :disabled="isBlocked || isQAOnly || !isProductSetup"
                      :state="errors.length ? 'invalid' : null"
                      v-model="products[index].product_code" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col md="3">
                <div class="form-group">
                  <label :for="'product-quantity-' + index">
                    Liczba sztuk:
                  </label>

                  <validation-provider
                    rules="required|min_value:1|only-natural-numbers"
                    :name="'product-quantity-' + index"
                    v-slot="{ errors }">
                    <d-input-group
                      append="szt."
                      :class="{'is-invalid': errors.length}">
                      <d-input
                        min="1"
                        step="1"
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        class="text-right"
                        v-model="products[index].quantity"
                        :state="errors.length ? 'invalid' : null"
                        type="number" />
                    </d-input-group>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col md="3">
                <div class="form-group">
                  <label :for="'product-color-' + index">
                    Kolor:
                  </label>

                  <validation-provider :name="'product-color-' + index">
                    <d-input
                      :disabled="isBlocked || isQAOnly || !isProductSetup"
                      v-model="products[index].color_name" />
                  </validation-provider>
                </div>
              </d-col>

              <d-col class="d-flex">
                <d-button
                  v-if="isProductSetup && !isQAOnly"
                  theme="danger"
                  outline
                  class="ml-auto align-self-center"
                  :disabled="isBlocked"
                  @click.prevent="removeProduct(index)">
                  <i class="material-icons">delete</i>
                  Usuń
                </d-button>
                <d-button
                  v-if="isQAOnly"
                  :disabled="isBlocked || leftToControlCount(product)"
                  theme="primary"
                  class="ml-auto align-self-center"
                  @click.prevent="$bus.$emit('quality-control-popup-show', product, orderName, allWorkers, product.services.length)">
                  Wprowadź wynik kontroli
                </d-button>
              </d-col>
            </d-row>

            <d-row
              class="d-flex">
              <d-col md="4">
                <div class="form-group">
                  <label :for="'product-type-' + index">
                    Typ czapki:
                  </label>
                  <validation-provider
                    :name="'product-type-' + index"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="isBlocked || isQAOnly || !isProductSetup"
                        :options="productTypeOptions"
                        v-model="products[index].type"
                        :reduce="type => type.value"
                        placeholder="Wybierz rodzaj czapki"
                        label="text">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </d-col>
              <d-col md="3">
                <div class="form-group">
                  <label :for="'product-color-' + index">
                    Rozmiar:
                  </label>

                  <d-input
                    :disabled="isBlocked || true"
                    :value="products[index].size_name || '-'" />
                </div>
              </d-col>
            </d-row>

            <d-row
              class="px-2 py-2 d-flex justify-content-around mx-0"
              style="background: #F5F6F8; border-radius: 0.625rem">
              <small class="mr-3">
                Liczba zdobień: <strong>{{ product.services.length }}</strong>
              </small>
              <small class="mr-3">
                Oczekuje na DST: <strong>{{ getAwaitingDSTCount(product.services) }}</strong>
              </small>
              <small class="mr-3">
                Oczekuje na próbę: <strong>{{ getAwaitingSampleCount(product.services) }}</strong>
              </small>
              <small class="mr-3">
                Oczekuje na akceptację próby: <strong>{{ getAwaitingSampleApprovalCount(product.services) }}</strong>
              </small>
              <small class="mr-3">
                Liczba wykonanych zdobień: <strong> {{ getExecutedCount(product.services) }}/{{ getServicesToExecuteCount(product.services) }}</strong>
              </small>
              <small class="mr-0">
                Liczba skontrolowanych: <strong :class="{ 'text-danger': getControlledCount(product).rejected }">{{ getControlledCount(product).amount }}/{{ product.quantity }}</strong>
              </small>
            </d-row>

            <d-row
              class="py-3 d-flex align-items-end">
              <d-col
                v-if="!userIsOffice"
                sm="10"
                class="text-primary">
                Koszt produktu: <strong>{{ roundProperly(getTotalProductPrice(product)) }} zł</strong>
              </d-col>
              <d-col
                :sm="userIsOffice ? 12 : 2"
                class="my-auto ml-auto"
                style="text-align: center;">
                <d-button
                  v-if="isProductSetup"
                  theme="success"
                  class="ml-auto d-block"
                  :disabled="isBlocked"
                  @click.prevent="$refs['order-creator-service'][index].addService()">
                  <i class="material-icons">add</i>
                  Dodaj zdobienie
                </d-button>
              </d-col>
            </d-row>
          </div>

          <d-row
            v-if="status === 'WAITING_ADDITIONAL_PRODUCTS' && product.control_rejected_count > 0"
            class="py-3 d-flex align-items-center">
              <d-col
                sm="8">
                <d-alert
                  theme="danger"
                  show
                  class="my-0">
                  W wyniku kontroli odrzuconych zostało  <strong>{{ product.control_rejected_count }}</strong> produktów, które trzeba domówić.
                </d-alert>
              </d-col>
              <d-col
                sm="4">
                <d-button
                  theme="primary"
                  class="ml-auto d-block"
                  :disabled="isBlocked"
                  @click.prevent="setProductsAsDelivered(product)">
                  Produkty dostarczone
                </d-button>
              </d-col>
          </d-row>

          <div
            class="d-flex flex-column">
            <d-row class="mt-0 ml-0">
              <d-link
                class="dropdown-toggle"
                v-d-toggle="'product-' + index + '-services-collapse'">
                Zdobienia
              </d-link>
            </d-row>
            <d-collapse
              visible
              :id="'product-' + index + '-services-collapse'">
              <order-creator-service
                ref="order-creator-service"
                :services="product.services"
                :productID="product.id"
                :productIndex="index"
                :productQuantity="product.quantity"
                :orderName="orderName"
                :productCode="product.product_code"
                :allContractors="allContractors"
                :allEmbroideryPositions="allEmbroideryPositions"
                :allEmbroideryTypes="allEmbroideryTypes"
                :allServiceGroups="allServiceGroups"
                :allServices="allServices"
                :allStrands="allStrands"
                :allMachines="allMachines"
                :allWorkers="allWorkers"
                :isBlocked="isBlocked"
                :isQAOnly="isQAOnly"
                :isSamplesOnly="isSamplesOnly"
                :isInPreparation="isInPreparation"
                :isProductSetup="isProductSetup"
                :toBeDelivered="status === 'WAITING_ADDITIONAL_PRODUCTS' && product.control_rejected_count > 0"
                :status="status"
                :rejectedCount="getRejectedCount(product)"
                :performPriceRecalculate="performPriceRecalculate" />
            </d-collapse>
          </div>
        </d-card-body>
      </d-card>
    </div>

    <div
      v-if="!allDataLoaded && !!products.length"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CustomDecimals from '@/utils/CustomDecimals.js';
import OrderCreatorService from './OrderCreatorService.vue';
import ProductTypes from '@/data/product-types.js';

export default {
  name: 'order-creator',
  props: [
    'orderedProducts',
    'orderName',
    'orderForm',
    'isBlocked',
    'isQAOnly',
    'isSamplesOnly',
    'isInPreparation',
    'isProductSetup',
    'status',
    'performPriceRecalculate'
  ],
  components: {
    'order-creator-service': OrderCreatorService
  },
  computed: {
    allDataLoaded () {
      return this.dataLoaded && this.loadedServiceGroups && this.loadedServices && this.loadedStrands && this.loadedMachines && this.loadedWorkers && this.loadedEmbroideryType && this.loadedEmbroideryPositions && this.loadedContractors;
    },
    formErrors () {
      return this.orderForm.errors;
    },
    productTypeOptions () {
      return ProductTypes;
    },
    userIsOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    hasProductsWithServicesZeroQuantity () {
      if (!this.products.length) {
        return false;
      }
      return !!this.products.filter(product => product.services.some(service => service.quantity === 0)).length;
    }
  },
  data () {
    return {
      allContractors: [],
      allEmbroideryPositions: [],
      allEmbroideryTypes: [],
      allMachines: [],
      allServiceGroups: [],
      allServices: [],
      allStrands: [],
      allWorkers: [],
      dataLoaded: false,
      detailsVisible: true,
      loadedContractors: false,
      loadedEmbroideryPositions: false,
      loadedEmbroideryType: false,
      loadedMachines: false,
      loadedServiceGroups: false,
      loadedServices: false,
      loadedStrands: false,
      loadedWorkers: false,
      loadError: false,
      products: [],
      productsDeliveredInProgress: false
    };
  },
  mounted () {
    this.loadAdditionalData();
  },
  methods: {
    addProduct () {
      this.products.push({
        id: null,
        product_code: null,
        quantity: null,
        color_name: '',
        type: null,
        services: [],
        control_rejected_count: 0
      });

      Vue.nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        this.dataLoaded = true;
      });
    },
    removeProduct (productIndex) {
      Vue.swal({
        title: 'Czy na pewno chcesz usunąć ten produkt?',
        text: 'Aby operacja została zapisana należy zapisać zamówienie',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.products.splice(productIndex, 1);
        }
      });
    },
    getProducts () {
      let productsToSend = [];
      for (let i = 0; i < this.products.length; i++) {
        productsToSend.push({
          id: this.products[i].id,
          product_code: this.products[i].product_code,
          quantity: parseFloat(this.products[i].quantity),
          color_name: this.products[i].color_name,
          type: this.products[i].type,
          services: this.$refs['order-creator-service'][i].getServices()
        });
      }

      return productsToSend;
    },
    setProducts (orderedProducts) {
      this.resetView();

      for (let i = 0; i < orderedProducts.length; i++) {
        for (let j = 0; j < orderedProducts[i].services.length; j++) {
          if (orderedProducts[i].services[j].dst_receive_date) {
            orderedProducts[i].services[j].dstReceiveDate = {
              startDate: new Date(orderedProducts[i].services[j].dst_receive_date)
            };
          } else {
            orderedProducts[i].services[j].dstReceiveDate = {
              startDate: null
            };
          }
        }

        this.products.push(orderedProducts[i]);
      }
      this.dataLoaded = true;
    },
    resetView () {
      this.products = [];
      this.products = [];
      this.dataLoaded = false;
      this.detailsVisible = true;
      this.allServiceGroups = [];
      this.allServices = [];
      this.allStrands = [];
      this.allMachines = [];
      this.allWorkers = [];
      this.loadedServiceGroups = false;
      this.loadedServices = false;
      this.loadedStrands = false;
      this.loadedMachines = false;
      this.loadedWorkers = false;
      this.loadError = false;
      this.productsDeliveredInProgress = false;

      this.loadAdditionalData();
    },
    handleToggle() {
      this.detailsVisible = !this.detailsVisible;
    },
    getAwaitingDSTCount (services) {
      return services.filter(service => !service.dst_settled).length;
    },
    getAwaitingSampleCount (services) {
      let counter = 0;
      for (let i = 0; i < services.length; i++) {
        counter += services[i].samples.filter(sample => sample.status === 'REJECTED').length;
      }
      return counter;
    },
    getAwaitingSampleApprovalCount (services) {
      let counter = 0;
      for (let i = 0; i < services.length; i++) {
        counter += services[i].samples.filter(sample => sample.status === 'WAITING').length;
      }
      return counter;
    },
    getExecutedCount (services) {
      let counter = 0;

      for (let i = 0; i < services.length; i++) {
        for (let j = 0; j < services[i].machines.length; j++) {
          counter += services[i].machines[j].executions.reduce((sum, item) => sum + item.execution, 0);
        }
      }
      return counter;
    },
    getServicesToExecuteCount(services) {
      let counter = 0;
      for (let i = 0; i < services.length; i++) {
        if (services[i].quantity) {
          counter += Number(services[i].quantity);
        }
      }
      return counter;
    },
    getControlledCount (product) {
      if (!product.controls) {
        return { amount: 0, rejected: 0 };
      }

      let counter = 0;
      let isRejected = false;
      for (let i = 0; i < product.controls.length; i++) {
        counter += product.controls[i].correct + product.controls[i].rejected;
        if (product.controls[i].rejected > 0) {
          isRejected = true;
        }
      }
      return { amount: counter, rejected: isRejected };
    },
    setProductsAsDelivered (product) {
      this.productsDeliveredInProgress = true;

      this.$http.request({
        method: 'POST',
        url: '/api/orders/products/set-delivered/' + product.id,
        headers: { 'Content-Type': undefined },
      }).then(() => {
        this.productsDeliveredInProgress = false;
        this.$bus.$emit('reload-order-view');
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas zapisywania',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.productsDeliveredInProgress = false;
      });
    },
    getRejectedCount (product) {
      if (product.control_rejected_count > 0 || !product.controls || !product.controls.length) {
        return 0;
      }

      return product.controls.map(control => control.rejected).reduce((sum, control) => sum + control);
    },
    getTotalProductPrice (product) {
      return product.services.reduce((sum, item) => sum + item.totalPrice, 0)
    },
    getTotalOrderPrice () {
      let totalPrice = 0;

      for (let i = 0; i < this.products.length; i++) {
        totalPrice += this.getTotalProductPrice(this.products[i]);
      }

      return totalPrice
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/groups/items',
        method: 'get',
        outputKey: 'allServiceGroups',
        loadedKey: 'loadedServiceGroups',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/items',
        method: 'get',
        outputKey: 'allServices',
        loadedKey: 'loadedServices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/strands/items',
        method: 'get',
        outputKey: 'allStrands',
        loadedKey: 'loadedStrands',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'loadedMachines',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1
          }
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/embroidery-types/items',
        method: 'get',
        outputKey: 'allEmbroideryTypes',
        loadedKey: 'loadedEmbroideryType',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/embroidery-positions/items',
        method: 'get',
        outputKey: 'allEmbroideryPositions',
        loadedKey: 'loadedEmbroideryPositions',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/contractors/items',
        method: 'get',
        outputKey: 'allContractors',
        loadedKey: 'loadedContractors',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    roundProperly (value, decimals = 2) {
      return CustomDecimals.roundProperly(value, decimals);
    },
    leftToControlCount (product) {
      let deliveredQuantity = product.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
      let controlledQuantity = 0;
      if (product.controls) {
        for (let i = 0; i < product.controls.length; i++) {
          controlledQuantity += product.controls[i].correct;
          controlledQuantity += product.controls[i].rejected;
        }
      }

      return ((product.quantity + deliveredQuantity) - controlledQuantity) <= 0;
    },
    removeServicesWithZeroQuantity () {
      for (let i = 0; i < this.$refs['order-creator-service'].length; i++) {
        this.$refs['order-creator-service'][i].removeServicesWithQuantityZero();
      }
    }
  }
};
</script>

<style lang="scss">
.product-info {
  &-select {
    min-width: 230px;
  }
}

@media screen and (max-width: 1200px) {
  .product-info {
    &-pricing {
      flex-wrap: wrap!important;

      > div {
        margin-left: 1rem !important;
        min-width: 55%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-info {
    &-select {
      width: 100%!important;
    }
    &-pricing {
      margin-top: 20px;
      width: 100%!important;

      > div {
        margin-left: 0!important;
        margin-right: 1rem!important;
        min-width: unset;
      }
    }
  }
}
</style>
