<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">
          Kontrola zamówionych produktów
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/zamowienia')"
          class="mr-3">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          :disabled="leftToControlCount === 0"
          theme="success"
          @click.prevent="$bus.$emit('quality-control-popup-show', data, data.order.order_name, [], data.services.length)">
          Wprowadź wynik kontroli
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!loaded"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <validation-observer
          ref="order-controller-form"
          tag="form">
          <d-tabs
            card
            :class="{'is-loading': !loaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="px-3 px-md-4">
               <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <div v-if="loaded">
                <d-row>
                  <d-col sm="12" md="6" lg="3">
                    <div class="form-group">
                      <label>
                        Kod produktu:
                      </label>
                      <div class="text-primary">
                        {{ data.product_code }}
                      </div>
                    </div>
                  </d-col>
                  <d-col sm="12" md="6" lg="3">
                    <div class="form-group">
                      <label>
                        Kolor:
                      </label>
                      <div class="text-primary">
                        {{ data.color_name }}
                      </div>
                    </div>
                  </d-col>
                  <d-col sm="12" md="6" lg="3">
                    <div class="form-group">
                      <label>
                        Pozostało do skontrolowania:
                      </label>
                      <div class="text-primary">
                        {{leftToControlCount }} szt. (z {{ data.quantity }} szt.)
                      </div>
                    </div>
                  </d-col>
                  <d-col sm="12" md="6" lg="3">
                    <div class="form-group">
                      <label>
                        Wyniki dotychczasowych kontroli:
                      </label>
                      <div>
                        <span class="text-primary">
                          poprawne: {{ getCorrectAmount() }} szt.
                        </span><br>
                        <span class="text-danger">
                          odrzucone: {{ getRejectedAmount() }} szt.
                        </span>
                      </div>
                    </div>
                  </d-col>
                </d-row>

                <h6 class="mb-3 py-3 border-bottom">
                  Zdobienia:
                </h6>
                <d-card
                  v-for="(service, indexS) of data.services"
                  :key="'service-item-' + indexS"
                  class="mt-2">
                  <d-card-body class="px-2 px-lg-3 py-3 py-lg-4">
                    <d-row>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Zdobienie:
                          </label>
                          <div class="text-primary">
                            {{ service.service_name }}
                          </div>
                        </div>
                      </d-col>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Prawdziwy wariant zdobienia:
                          </label>
                          <div class="text-primary">
                            <template v-if="service.real_service_name">
                              {{ service.real_service_name }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </div>
                      </d-col>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Grupa zdobień:
                          </label>
                          <div class="text-primary">
                            {{ service.service_group_name }}
                          </div>
                        </div>
                      </d-col>
                    </d-row>
                    <d-row>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Numer DST:
                          </label>
                          <div>
                            <template v-if="getDstNumber(service)">
                              <span class="text-primary">
                                {{ getDstNumber(service) }}
                              </span>
                            </template>
                            <template v-else>
                              brak
                            </template>
                          </div>
                        </div>
                      </d-col>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Liczba ściegów:
                          </label>

                          <div>
                            <template v-if="getStitchNumber(service)">
                              <span class="text-primary">
                                {{ getStitchNumber(service) }}
                              </span>
                            </template>
                            <template v-else>
                              brak
                            </template>
                          </div>
                        </div>
                      </d-col>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            Kolory nici:
                          </label>
                          <div>
                            <template v-if="getStrands(service)">
                              <span class="text-primary">
                                {{ getStrands(service) }}
                              </span>
                            </template>
                            <template v-else>
                              brak
                            </template>
                          </div>
                        </div>
                      </d-col>
                    </d-row>
                    <d-row>
                      <d-col sm="4">
                        <div class="form-group">
                          <label>
                            DST:
                          </label>
                          <div class="text-primary">
                            {{ getDSTType(service.dst) }}
                          </div>
                        </div>
                      </d-col>
                    </d-row>

                    <d-row class="mb-3 pt-3 border-bottom">
                      <d-col sm="12">
                        <h5>Próby zdobienia</h5>
                      </d-col>
                    </d-row>

                    <template v-if="service.samples.length">
                      <order-creator-service-samples-info
                        ref="order-creator-service-samples-info"
                        :samples="service.samples"/>
                    </template>
                    <template v-else>
                      Brak prób dla tego zdobienia.
                    </template>
                  </d-card-body>
                </d-card>
              </div>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </validation-observer>
      </d-card>
    </d-form>
    <order-creator-qa-popup />
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';
import ProductTypes from '@/data/product-types.js';
import DstTypes from '@/data/dst-types.js';
import OrderCreatorQualityControlPopup from '../popups/OrderCreatorQualityControlPopup.vue';
import OrderCreatorServiceSamplesInfo from './order/OrderCreatorServiceSamplesInfo.vue';

export default {
  name: 'order-controller-form',
  components: {
    'order-creator-qa-popup': OrderCreatorQualityControlPopup,
    'order-creator-service-samples-info': OrderCreatorServiceSamplesInfo
  },
  computed: {
    leftToControlCount () {
      return (this.data.quantity + this.getDeliveredAmount()) - (this.getCorrectAmount() + this.getRejectedAmount());
    },
    productTypeOptions () {
      return ProductTypes;
    },
    dstOptions () {
      return DstTypes;
    }
  },
  data() {
    return {
      data: {
        color_name: null,
        controls: [],
        id: 0,
        order: null,
        product_code: null,
        quantity: null,
        services: [],
        type: null
      },
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    this.loadItemData();
    this.$bus.$on('reload-order-view', this.loadItemData);
  },
  methods: {
    loadItemData (isControlDone = false) {
      if (isControlDone) {
        this.$router.push('/zamowienia');
        return;
      }

      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/orders/products/controller-item/',
        instanceFields: ['id', 'order', 'controls', 'services', 'type', 'color_name', 'quantity', 'product_code'],
        loadedKey: 'loaded'
      });
    },
    getDSTType (dstValue) {
      if (!dstValue) {
        return '-';
      }
      let itemWithValue = this.dstOptions.find(dst => dst.value === dstValue);
      if (itemWithValue && itemWithValue.text) {
        return itemWithValue.text;
      }

      return '-';
    },
    getCorrectAmount () {
      return this.data.controls.map(control => control.correct).reduce((sum, control) => sum + control, 0);
    },
    getRejectedAmount () {
      return this.data.controls.map(control => control.rejected).reduce((sum, control) => sum + control, 0);
    },
    getDeliveredAmount () {
      return this.data.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
    },
    getDstNumber (service) {
      let mostRecentDstNumber = service.dst_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].dst_number) {
            mostRecentDstNumber = acceptedSamples[i].dst_number;
          }
        }
      }
      return mostRecentDstNumber;
    },
    getStitchNumber (service) {
      let mostRecentStitchNumber = service.stitch_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].stitch_number) {
            mostRecentStitchNumber = acceptedSamples[i].stitch_number;
          }
        }
      }
      return mostRecentStitchNumber;
    },
    getStrands (service) {
      let mostRecentStrands = service.strands;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].strands && acceptedSamples[i].strands.length) {
            mostRecentStrands = acceptedSamples[i].strands;
          }
        }
      }

      let strandsString = '';
      for (let i = 0; i < mostRecentStrands.length; i++) {
        strandsString += mostRecentStrands[i].strand_name + ';  ';
      }

      return strandsString;
    }
  },
  beforeDestroy () {
    this.$bus.$off('reload-order-view', this.loadItemData);
  }
};
</script>
