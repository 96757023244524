export default function () {
  return [
    {
      title: 'Strona główna',
      to: '/kokpit',
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: '',
      permissions: 'dashboard',
      counter: 'newOrders'
    },
    {
      title: 'Operatorzy i maszyny',
      to: '/operatorzy-i-maszyny',
      htmlBefore: '<i class="material-icons">work</i>',
      htmlAfter: '',
      permissions: 'working_hours'
    },
    {
      title: 'Zamówienia',
      to: '/zamowienia',
      htmlBefore: '<i class="material-icons">shopping_basket</i>',
      htmlAfter: '',
      permissions: 'orders',
      counter: 'newOrders'
    },
    {
      title: 'Harmonogram',
      to: '/harmonogram',
      htmlBefore: '<i class="material-icons">calendar_today</i>',
      htmlAfter: '',
      permissions: 'schedules',
      counter: 'scheduleWithoutDate'
    },
    {
      title: 'Czas pracy',
      to: '/czas-pracy',
      htmlBefore: '<i class="material-icons">timer</i>',
      htmlAfter: '',
      permissions: 'working_times'
    },
    {
      title: 'Użytkownicy',
      to: '/uzytkownicy',
      htmlBefore: '<i class="material-icons">verified_user</i>',
      htmlAfter: '',
      permissions: 'users'
    },
    {
      title: 'Maszyny',
      to: '/maszyny',
      htmlBefore: '<i class="material-icons">print</i>',
      htmlAfter: '',
      permissions: 'machines'
    },
    {
      title: 'Zdobienia',
      to: '/zdobienia',
      htmlBefore: '<i class="material-icons">star</i>',
      htmlAfter: '',
      permissions: 'services',
      items: [
        {
          title: 'Wszystkie zdobienia',
          to: '/zdobienia'
        },
        {
          title: 'Grupy zdobień',
          to: '/zdobienia/grupy'
        }
      ]
    },
    {
      title: 'Cenniki',
      htmlBefore: '<i class="material-icons">monetization_on</i>',
      htmlAfter: '',
      permissions: 'prices',
      items: [
        {
          title: 'Cenniki zdobień',
          to: '/cenniki/zdobienia'
        },
        {
          title: 'Cenniki setup',
          to: '/cenniki/setup'
        }
      ]
    },
    {
      title: 'Wykonawcy',
      to: '/wykonawcy',
      htmlBefore: '<i class="material-icons">design_services</i>',
      htmlAfter: '',
      permissions: 'contractors'
    },
    {
      title: 'Statystyki',
      to: '/statystyki',
      htmlBefore: '<i class="material-icons">poll</i>',
      htmlAfter: '',
      permissions: 'stats'
    },
    {
      title: 'Ustawienia',
      to: '/ustawienia',
      htmlBefore: '<i class="material-icons">settings_applications</i>',
      htmlAfter: '',
      permissions: 'strands',
      items: [
        {
          title: 'Nici',
          to: '/ustawienia/nici',
        },
        {
          title: 'Rodzaje haftów',
          to: '/ustawienia/rodzaje-haftow',
        },
        {
          title: 'Pozycje dekoracji',
          to: '/ustawienia/pozycje-dekoracji'
        }
      ]
    },
    {
      title: 'Wyloguj się',
      htmlBefore: '<i class="material-icons">power_settings_new</i>',
      htmlAfter: '',
      permissions: false,
      to: '/wyloguj'
    }
  ];
}
